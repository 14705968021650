import {
  Box,
  Image,
  Input,
  IconButton,
  Flex,
  Button,
  ImageProps,
} from "@chakra-ui/react";
import { Control, useController } from "react-hook-form";
import { uploadImage } from "../../services/api/images";
import { BiTrashAlt } from "react-icons/bi";
import { useRef, useState } from "react";
import { converHeicToPng, getImageUrl } from "../../services/images";

interface PhotoUploadInputProps {
  name: string;
  control: Control<any>;
  type: "single" | "multiple";
  previewProps?: ImageProps;
}

export const PhotoUploadInput: React.FC<PhotoUploadInputProps> = ({
  name,
  control,
  type,
  previewProps,
}) => {
  const [uploading, setUploading] = useState(false);
  const [fileByPhotoId, setFileByPhotoId] = useState<
    Record<string, string | File>
  >({});
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: type === "single" ? null : [],
  });

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    let file = files[0];
    setUploading(true);

    if (file.type === "image/heic") {
      file = await converHeicToPng(file);
    }

    const photoId = await uploadImage(file);
    const nextPhotoIds = type === "single" ? photoId : [photoId, ...value];
    setFileByPhotoId((prev) => ({
      ...prev,
      [photoId]: URL.createObjectURL(file),
    }));
    setUploading(false);
    onChange(nextPhotoIds);
  };

  const handleRemove = (index: number) => () => {
    if (type === "single") {
      onChange(null);
    } else {
      onChange([...value].splice(index, 1));
    }
  };

  return (
    <>
      <Flex marginY={2} flexWrap="wrap" gap={1}>
        {(type === "single" ? (value ? [value] : []) : value).map(
          (photoId: string, index: number) => (
            <Box key={photoId} position="relative" width="calc(50% - 2px)">
              <Image
                src={fileByPhotoId[photoId]?.toString() || getImageUrl(photoId)}
                borderRadius={24}
                width="200px"
                height="200px"
                fit="cover"
                {...previewProps}
              />
              <IconButton
                aria-label="Delete"
                icon={<BiTrashAlt />}
                variant="overlay"
                position="absolute"
                top={3}
                right={3}
                size="sm"
                fontSize="md"
                onClick={handleRemove(index)}
              />
            </Box>
          )
        )}
      </Flex>

      <Input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        display="none"
      />
      <Button
        variant="outline"
        size="lg"
        width="100%"
        onClick={handleClick}
        isLoading={uploading}
      >
        Upload photo
      </Button>
    </>
  );
};
