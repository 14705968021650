import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Text,
  useNumberInput,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../providers/AuthProvider";
import { VianikoEvent } from "../../../../types/events";
import { SlidingPrice } from "./SlidingPrice";
import { authUrl } from "../../../../services/routes/urlBuilder";
import { priceInDollars } from "../../../../services/payments";

const MIN_TICKETS = 1;
const MAX_TICKETS_DEFAULT = 10;

interface PricingFormProps {
  event: VianikoEvent;
}

export const PricingForm: React.FC<PricingFormProps> = ({
  event,
  ...props
}) => {
  const { user } = useAuth();
  const [price, setPrice] = React.useState<number>(event.price_in_cents);
  const [numTickets, setNumTickets] = React.useState<number>(MIN_TICKETS);
  const navigate = useNavigate();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      value: numTickets,
      min: MIN_TICKETS,
      max: event.max_num_tickets || MAX_TICKETS_DEFAULT,
      onChange: (value: string) => {
        const numTickets = parseInt(value) || MIN_TICKETS;
        setNumTickets(numTickets);
      },
    });

  const handleChangePrice = (price: number) => {
    setPrice(price);
  };

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    const eventPaymentUrl = `/events/${event.id}/payments?amount=${price}&numTickets=${numTickets}`;

    if (user) {
      navigate(eventPaymentUrl);
    } else {
      navigate(authUrl(eventPaymentUrl));
      return;
    }
  };

  if (!event) return null;

  return (
    <Box {...props}>
      <form onSubmit={submitForm}>
        {event.max_num_tickets && event.max_num_tickets > 1 && (
          <Box marginY={8}>
            <Text>Number of attendees</Text>
            <HStack marginTop={2}>
              <Button marginTop={0} {...getDecrementButtonProps()}>
                -
              </Button>
              <Input width="4rem" {...getInputProps()} />
              <Button marginTop={0} {...getIncrementButtonProps()}>
                +
              </Button>
            </HStack>
          </Box>
        )}
        {event.payment_type === "fixed" ? (
          <>
            <Button type="submit" variant="primary" size="lg" width="100%">
              Join for {priceInDollars(price)}
            </Button>
          </>
        ) : event.payment_type === "slider" ? (
          <Flex>
            <Button type="submit" variant="primary" size="lg">
              Join for {priceInDollars(price, 0)}
            </Button>
            <SlidingPrice
              event={event}
              onChange={handleChangePrice}
              flexGrow={1}
              marginTop={3}
              marginLeft={1}
            />
          </Flex>
        ) : event.payment_type === "free" ? (
          <Button type="submit" variant="primary" size="lg" width="100%">
            Join for Free
          </Button>
        ) : null}
      </form>
    </Box>
  );
};
