import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

let authInterceptorId: number | null = null;

export const setupAuthInterceptor = (idToken: string | null) => {
  if (authInterceptorId !== null) {
    instance.interceptors.request.eject(authInterceptorId);
  }

  authInterceptorId = instance.interceptors.request.use(
    async (config) => {
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
      } else {
        delete config.headers.Authorization;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const setupAuthRefreshInterceptor = (refreshToken: () => void) => {
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        error.response?.data?.code === "auth/id-token-expired"
      ) {
        await refreshToken();
        return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );
};

export default instance;
