import axiosInstance from "./axiosInstance";

export const getEventUser = async (eventUserId: string) => {
  const response = await axiosInstance.get(`/event_users/${eventUserId}`);
  return response.data;
};

export const fetchCurrentEventUser = async (eventId: string) => {
  const response = await axiosInstance.get(`/event_users/${eventId}/my`);
  return response.data;
};

export const createEventUser = async (eventId: string, userId: string) => {
  const response = await axiosInstance.post("/event_users", {
    event_id: eventId,
    user_id: userId,
  });
  return response.data;
};

export const updateEventUser = async (
  eventUserId: string,
  data: { findable: boolean }
) => {
  const response = await axiosInstance.put(`/event_users/${eventUserId}`, data);
  return response.data;
};
