import { useEffect, useState } from "react";
import { RecurringEvent } from "../../types/recurring_events";
import { RecurringEventForm } from "./Form";
import * as api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteButtonConfirmation } from "../../components/DeleteButtonConfirmation";
import { deleteRecurringEvent } from "../../services/api/recurringEvents";
import { organizationShowUrl } from "../../services/routes/urlBuilder";

export const EditRecurringEvent: React.FC = () => {
  const [recurringEvent, setRecurringEvent] = useState<RecurringEvent>();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecurringEvent = async () => {
      if (!id) return;

      const recurringEvent = await api.recurringEvents.fetchRecurringEvent(id);
      setRecurringEvent(recurringEvent);
    };

    fetchRecurringEvent();
  }, [id]);

  const handleSubmit = async (data: RecurringEvent) => {
    if (!id) return;
    await api.recurringEvents.updateRecurringEvent(id, data);
    if (recurringEvent?.organization_id) {
      navigate(organizationShowUrl(recurringEvent!.organization_id));
    }
  };

  const handleDelete = async () => {
    await deleteRecurringEvent(id!);
    if (recurringEvent?.organization_id) {
      navigate(organizationShowUrl(recurringEvent!.organization_id));
    }
  };

  if (!recurringEvent || !id) return null;

  return (
    <>
      <RecurringEventForm onSubmit={handleSubmit} values={recurringEvent} />
      <DeleteButtonConfirmation onConfirm={handleDelete} />
    </>
  );
};
