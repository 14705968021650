import { addDays, format, isToday, isTomorrow } from "date-fns";

export const formatDateUpcomingEvent = (date: Date): string => {
  if (isToday(date)) {
    return "Today";
  } else if (date < addDays(new Date(), 6)) {
    return format(date, "EEEE");
  } else {
    return format(date, "MMM d");
  }
};

export const formatDatePastEvent = (date: Date): string => {
  return format(date, "MMM d");
};

export const formatDateOrganizationNextEvent = (date: Date): string => {
  if (isToday(date)) {
    return "Today";
  } else if (isTomorrow(date)) {
    return "Tomorrow";
  } else if (date < addDays(new Date(), 6)) {
    return format(date, "EEEE");
  } else if (date < addDays(new Date(), 14)) {
    return "Next week";
  } else {
    return format(date, "MMM d");
  }
};
