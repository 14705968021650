import { Box, ChakraProps } from "@chakra-ui/react";
import { ReactComponent as InstagramLogo } from "../../assets/social/instagram-logo.svg";
import { ReactComponent as TwitterLogo } from "../../assets/social/twitter-logo.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/social/linkedin-logo.svg";

interface SocialIconProps extends ChakraProps {
  type: "instagram" | "twitter" | "linkedin";
  size?: string;
}

const DEFAULT_SIZE = "24px";

export const SocialIcon: React.FC<SocialIconProps> = ({
  type,
  size = DEFAULT_SIZE,
  ...props
}) => {
  const Logo =
    type === "instagram"
      ? InstagramLogo
      : type === "twitter"
      ? TwitterLogo
      : type === "linkedin"
      ? LinkedinLogo
      : null;

  if (!Logo) return null;

  return (
    <Box {...props} as={Logo} height={size} width={size} borderRadius={5} />
  );
};
