import { useCallback, useEffect, useState } from "react";
import { fetchUserEventsHostedCount } from "../services/api/users";

interface UseUserEventsHostedCount {
  count: number;
  refetch: () => void;
}

export const useUserEventsHostedCount = (
  userId: string | undefined
): UseUserEventsHostedCount => {
  const [count, setCount] = useState<number>(0);

  const fetch = useCallback(async () => {
    if (!userId) return 0;
    const count = await fetchUserEventsHostedCount(userId);
    setCount(count);
  }, [userId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { count, refetch: fetch };
};
