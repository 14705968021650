import { useCallback, useEffect, useState } from "react";
import { Organization } from "../types/organization";
import { fetchUserOrganizations } from "../services/api/users";

export interface UseOrganizations {
  organizations: Organization[] | undefined;
  refetch: () => void;
}

export const useOrganizations = (
  userId: string | undefined
): UseOrganizations => {
  const [organizations, setOrganizations] = useState<Organization[]>();

  const fetch = useCallback(async () => {
    if (!userId) return;
    const organizations = await fetchUserOrganizations(userId);
    setOrganizations(organizations);
  }, [userId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { organizations, refetch: fetch };
};
