import {
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { BiArrowBack, BiDotsHorizontalRounded } from "react-icons/bi";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { homeUrl } from "../services/routes/urlBuilder";

interface PageHeaderProps {
  backLink?: string;
  menuListContent?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  backLink,
  menuListContent,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (backLink) {
      navigate(backLink);
    } else if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(homeUrl());
    }
  };

  return (
    <HStack height={12}>
      <LinkBox display="flex" onClick={handleBack}>
        <Icon as={BiArrowBack} />
        <LinkOverlay as={RouterLink} to="">
          <Heading size="sm" marginX={1}>
            Back
          </Heading>
        </LinkOverlay>
      </LinkBox>
      <Spacer />
      {menuListContent && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon as={BiDotsHorizontalRounded} />}
            variant="ghost"
          />
          <MenuList>{menuListContent}</MenuList>
        </Menu>
      )}
    </HStack>
  );
};
