import { useCallback, useEffect, useState } from "react";
import { VianikoEvent } from "../types/events";
import { fetchUserEvents } from "../services/api/users";
import { OrganizationTheme } from "../types/organization";
import { DatetimeDirection } from "../types/datetime";

export type UserProfileEvent = VianikoEvent & {
  organization_name: string;
  organization_id: string;
  theme: OrganizationTheme;
  findable?: boolean;
  user_id?: string;
  event_user_id?: string;
};

interface UseUserEvents {
  events: UserProfileEvent[] | undefined;
  refetch: () => void;
}

export const useUserEvents = (
  userId: string | undefined,
  direction?: DatetimeDirection
): UseUserEvents => {
  const [events, setEvents] = useState<UserProfileEvent[]>();

  const fetch = useCallback(async () => {
    if (!userId) return;
    const result = await fetchUserEvents(userId, direction || "future");
    setEvents(result);
  }, [userId, direction]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { events, refetch: fetch };
};
