import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { BACKGROUND_GRADIENT } from "../colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    position: "relative",
    margin: "auto",
    borderRadius: "24px",
    background: BACKGROUND_GRADIENT,
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
