import { Box, StyleProps } from "@chakra-ui/react";
import { useCommunityTheme } from "../hooks/useCommunityTheme";
import { OrganizationTheme } from "../types/organization";
import { EMPTY_CARD_BORDER_COLOR } from "../services/theme/colors";

interface ThemeCardProps extends StyleProps {
  theme?: OrganizationTheme;
  children?: React.ReactNode;
}

export const ThemeCard: React.FC<ThemeCardProps> = ({
  theme,
  children,
  ...styleProps
}) => {
  const { backgroundImage } = useCommunityTheme(theme);

  return (
    <Box
      width="100%"
      height="200px"
      position="relative"
      bgImage={backgroundImage}
      bgSize="cover"
      borderRadius="24px"
      border={backgroundImage === "none" ? "1px solid" : "none"}
      borderColor={
        backgroundImage === "none" ? EMPTY_CARD_BORDER_COLOR : "none"
      }
      {...styleProps}
    >
      {children}
    </Box>
  );
};
