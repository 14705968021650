import {
  Button,
  Flex,
  Icon,
  IconButton,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Panel } from "../../../components/Panel";
import { ExtendedOrganizationUser } from "../../../hooks/useOrganizationUsers";
import { useAllCurrentUserFriendships } from "../../../hooks/useAllCurrentUserFriendships";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useFriendshipRequests } from "../../events/hooks/useFriendshipRequests";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { BiDotsHorizontalRounded, BiXCircle } from "react-icons/bi";
import { updateOrganizationUserStatus } from "../../../services/api/organizationUsers";
import { userShowUrl } from "../../../services/routes/urlBuilder";
import { UserAvatar } from "../../../components/UserAvatar";

interface OrganizationMembersProps {
  organizationUsers: ExtendedOrganizationUser[];
  onUpdateStatus: (id?: string, status?: string) => Promise<void>;
}

export const OrganizationMembers: React.FC<OrganizationMembersProps> = ({
  organizationUsers,
  onUpdateStatus,
}) => {
  const { currentUser } = useCurrentUser();
  const { currentOrganizationUser } = useCurrentOrganizationUser();
  const { friendsByUserId } = useAllCurrentUserFriendships();
  const { create, friendshipRequestOutgoingByUserId } = useFriendshipRequests();

  const handleAddFriend = (userId: string) => async () => {
    await create(userId);
  };

  const handleStatus = (orgUser: ExtendedOrganizationUser) => async () => {
    await updateOrganizationUserStatus(orgUser.id, "banned");
    await onUpdateStatus();
  };

  return (
    <Panel>
      <Text>All members</Text>
      <Flex gap={4} marginTop={4} direction="column">
        {organizationUsers.map((orgUser) => (
          <LinkBox key={orgUser.id}>
            <Flex key={orgUser.id} gap={2} align="center">
              <UserAvatar
                size="md"
                name={orgUser.full_name}
                photoId={orgUser.photo_id}
              />
              <LinkOverlay as={RouterLink} to={userShowUrl(orgUser.user_id)}>
                <Text
                  flexGrow={4}
                  whiteSpace="nowrap"
                  noOfLines={1}
                  textDecoration={
                    orgUser.status === "banned" ? "line-through" : "none"
                  }
                >
                  {orgUser.full_name}
                </Text>
              </LinkOverlay>
              <Spacer />

              {friendsByUserId[orgUser.user_id] ? (
                <Button isDisabled>Friend</Button>
              ) : friendshipRequestOutgoingByUserId?.[orgUser.user_id] ? (
                <Button isDisabled>Awaiting</Button>
              ) : orgUser.user_id !== currentUser?.id ? (
                <Button
                  variant="primary"
                  onClick={handleAddFriend(orgUser.user_id)}
                >
                  Add friend
                </Button>
              ) : null}

              {currentOrganizationUser?.is_owner &&
                currentOrganizationUser.id !== orgUser.id && (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<Icon as={BiDotsHorizontalRounded} />}
                      variant="ghost"
                    />

                    <MenuList>
                      <Link onClick={handleStatus(orgUser)}>
                        <MenuItem>
                          <Icon as={BiXCircle} marginRight={2} />
                          Ban User
                        </MenuItem>
                      </Link>
                    </MenuList>
                  </Menu>
                )}
            </Flex>
          </LinkBox>
        ))}
      </Flex>
    </Panel>
  );
};
