import { useState, useEffect, useCallback } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { accountSession } from "../services/api/payments";
import { BRAND_PRIMARY_COLOR } from "../services/theme";

export const useStripeConnect = (organizationId: string | undefined) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<any>();

  const initialize = useCallback(async () => {
    if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
      throw new Error("Missing REACT_APP_STRIPE_PUB_KEY");
    }

    if (!organizationId) return;

    const fetchClientSecret = async () => {
      const response = await accountSession(organizationId);
      return response.client_secret;
    };

    setStripeConnectInstance(
      loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUB_KEY,
        fetchClientSecret,
        appearance: {
          overlays: "dialog",
          variables: {
            colorPrimary: BRAND_PRIMARY_COLOR,
          },
        },
      })
    );
  }, [organizationId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return { stripeConnectInstance, refetch: initialize };
};

export default useStripeConnect;
