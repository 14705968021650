import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BiFilterAlt } from "react-icons/bi";

interface FilterOption<T> {
  label: string;
  value: T;
}

interface FilterButtonMenuProps<T> {
  options: FilterOption<T>[];
  onSelect: (value: T) => void;
}

export function FilterButtonMenu<T extends string>({
  options,
  onSelect,
}: FilterButtonMenuProps<T>) {
  const handleSelect = (value: T) => () => {
    onSelect(value);
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          aria-label="Add event"
          icon={<BiFilterAlt />}
          fontSize="18px"
          marginTop={-4}
        />
        <MenuList>
          {options.map((option) => (
            <MenuItem key={option.value} onClick={handleSelect(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
