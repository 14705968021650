import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import * as api from "../services/api";
import { Organization } from "../types/organization";

interface OrganizationContextType {
  organization: Organization | undefined;
  refetch: () => Promise<void>;
}

const OrganizationContext = createContext<OrganizationContextType>({
  organization: undefined,
  refetch: async () => {},
});

interface OrganizationProviderProps {
  organizationId: string;
  children: React.ReactNode;
}

export const useOrganization = (
  organizationId: string | undefined
): OrganizationContextType => {
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );

  const fetchOrganization = useCallback(async () => {
    if (!organizationId) return;

    const organization = await api.organizations.fetchOrganization(
      organizationId
    );
    setOrganization(organization);
  }, [organizationId]);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  return { organization, refetch: fetchOrganization };
};

export const OrganizationProvider = ({
  organizationId,
  children,
}: OrganizationProviderProps) => {
  const { organization, refetch: fetchOrganization } =
    useOrganization(organizationId);

  return (
    <OrganizationContext.Provider
      value={{ organization, refetch: fetchOrganization }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useCurrentOrganization = (): OrganizationContextType => {
  return useContext(OrganizationContext);
};
