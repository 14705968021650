import { useEffect, useState } from "react";
import { VianikoUser } from "../types/users";
import * as api from "../services/api";

export const useVianikoUser = (
  id: string | undefined
): VianikoUser | undefined => {
  const [user, setUser] = useState<VianikoUser | undefined>(undefined);

  useEffect(() => {
    const fetchUser = async () => {
      if (id) {
        const user = await api.users.getUser(id);
        setUser(user);
      }
    };

    fetchUser();
  }, [id]);

  return user;
};
