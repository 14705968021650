import { useMemo } from "react";
import { organizationThemes, Organization } from "../../types/organization";
import { ThemeCard } from "../ThemeCard";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { BiCaretLeftCircle, BiCaretRightCircle } from "react-icons/bi";
import { Control, useController } from "react-hook-form";
import {
  TEXT_PRIMARY_COLOR,
  TEXT_SECONDARY_COLOR,
} from "../../services/theme/colors";

interface OrganizationThemeCarouselInputProps {
  control: Control<Organization>;
}
export const OrganizationThemeCarouselInput: React.FC<
  OrganizationThemeCarouselInputProps
> = ({ control }) => {
  const {
    field: { onChange, value },
  } = useController({
    name: "theme",
    control,
    defaultValue: "health",
  });

  const slideIndex = useMemo(() => {
    return organizationThemes.indexOf(value);
  }, [value]);

  return (
    <FormControl>
      <FormLabel>Theme of decoration</FormLabel>
      <ThemeCard theme={value}>
        <Flex width="100%" height="200px" align="center" paddingX={2}>
          <IconButton
            aria-label="back"
            variant="overlay"
            size="sm"
            icon={<Icon as={BiCaretLeftCircle} />}
            isDisabled={slideIndex === 0}
            onClick={() => onChange(organizationThemes[slideIndex - 1])}
          />

          <Spacer />
          <IconButton
            aria-label="forward"
            variant="overlay"
            size="sm"
            icon={<Icon as={BiCaretRightCircle} />}
            isDisabled={slideIndex === organizationThemes.length - 1}
            onClick={() => onChange(organizationThemes[slideIndex + 1])}
          />
        </Flex>
      </ThemeCard>
      <Input type="hidden" value={value} onChange={onChange} />
      <HStack justify="center">
        {organizationThemes.map((t, index) => (
          <Box
            key={t}
            height={1}
            width={1}
            borderRadius="4px"
            marginY={2}
            bgColor={
              index === slideIndex ? TEXT_PRIMARY_COLOR : TEXT_SECONDARY_COLOR
            }
          ></Box>
        ))}
      </HStack>
    </FormControl>
  );
};
