import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { GHOST_HOVER_COLOR } from "../services/theme/colors";
import { BiChevronDown } from "react-icons/bi";

export type Permission = "everyone" | "me";

interface PermissionDropdownProps {
  value: Permission;
  onChange: (value: Permission) => void;
}

export const PermissionDropdown: React.FC<PermissionDropdownProps> = ({
  value,
  onChange,
}) => {
  return (
    <Menu>
      <MenuButton
        backgroundColor={GHOST_HOVER_COLOR}
        borderRadius="24px"
        paddingY={1}
        paddingLeft={4}
        paddingRight={2}
      >
        <Flex>
          <Text>{value === "everyone" ? "Everyone" : "Just me"}</Text>
          <Icon as={BiChevronDown} />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => onChange("everyone")}>Everyone</MenuItem>
        <MenuItem onClick={() => onChange("me")}>Just me</MenuItem>
      </MenuList>
    </Menu>
  );
};
