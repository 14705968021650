import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spacer,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { mobileModalThemeProps } from "../services/theme/overrides/modal";
import { CopyToClipboardButton } from "./CopyToClipboardButton";
import { useAllCurrentUserFriendships } from "../hooks/useAllCurrentUserFriendships";
import { useEffect, useState } from "react";
import { TEXT_SECONDARY_COLOR } from "../services/theme/colors";
import { UserAvatar } from "./UserAvatar";
import { Panel } from "./Panel";

interface InviteFriendsModalProps {
  isOpen: boolean;
  onClose: () => void;
  inviteLink: string;
  onInvite?: (userIds: string[]) => Promise<void>;
  heading?: string;
  renderModalBodyTop?: React.ReactNode;
}

export const InviteFriendsModal: React.FC<InviteFriendsModalProps> = ({
  isOpen,
  onClose,
  inviteLink,
  onInvite,
  heading,
  renderModalBodyTop,
}) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [invited, setInvited] = useState(false);
  const { friends } = useAllCurrentUserFriendships();

  const modalContentProps = useBreakpointValue({
    base: mobileModalThemeProps,
    lg: {},
  });

  useEffect(() => {
    if (isOpen) {
      setSelectedUserIds([]);
      setInvited(false);
    }
  }, [isOpen]);

  const handleChangeCheckbox = (userId: string) => () => {
    setSelectedUserIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleInvite = async () => {
    await onInvite?.(selectedUserIds);
    setInvited(true);
  };

  return (
    <Portal>
      <Modal
        size="md"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent {...modalContentProps}>
          <ModalHeader>
            <Heading as="h2" size="xl">
              {heading || "Invite people"}
            </Heading>
          </ModalHeader>
          <ModalCloseButton margin={2}>
            <Icon as={AiOutlineCloseCircle} fontSize={36} />
          </ModalCloseButton>
          <ModalBody>
            <>
              {renderModalBodyTop}
              <Panel>
                <Heading size="sm">Spread the word</Heading>

                <Box marginTop={2} marginBottom={4}>
                  <CopyToClipboardButton text={inviteLink} />
                </Box>

                {friends && friends.length > 0 && (
                  <VStack gap={4}>
                    {friends.map((friend) => (
                      <HStack key={friend.id} width="100%">
                        <UserAvatar size="md" user={friend} />
                        <Text>{friend.full_name}</Text>
                        <Spacer />
                        {invited && selectedUserIds.includes(friend.id) ? (
                          <Text color={TEXT_SECONDARY_COLOR}>Invited</Text>
                        ) : (
                          <Checkbox
                            onChange={handleChangeCheckbox(friend.id)}
                          />
                        )}
                      </HStack>
                    ))}
                  </VStack>
                )}
              </Panel>
            </>
          </ModalBody>
          {friends && friends.length > 0 && (
            <ModalFooter>
              <Button
                variant="primary"
                onClick={handleInvite}
                width="100%"
                marginBottom={12}
                isDisabled={selectedUserIds.length === 0 || invited}
              >
                {invited
                  ? `${selectedUserIds.length} friends have been invited!`
                  : "Invite"}
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Portal>
  );
};
