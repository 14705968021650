import { Outlet, createBrowserRouter } from "react-router-dom";
import { PhoneAuth } from "../../pages/auth/PhoneAuth";
import { Root } from "../../pages/root";
import { OrganizationShowPage } from "../../pages/organizations/Show";
import {
  EventShowPage,
  loader as eventShowPageLoader,
} from "../../pages/events/Show";
import {
  EventPaymentPage,
  loader as eventPaymentPageLoader,
} from "../../pages/payments/EventPayment";
import { ErrorPage } from "../../pages/errors";
import { AuthenticatedRoutes } from "../../components/AuthenticatedRoutes";
import { UserShowPage } from "../../pages/users/Show";
import { UserEditPage } from "../../pages/users/Edit";
import { MyEventsPage } from "../../pages/events/MyEventsPage";
import { Home } from "../../pages/Home";
import { MyOrganization } from "../../pages/organizations/MyOrganization";
import { NewEventPage } from "../../pages/events/New";
import { OrganizationParentPage } from "../../pages/organizations/OrganizationParentPage";
import { EditEventPage } from "../../pages/events/Edit";
import { NewOrganization } from "../../pages/organizations/New";
import { EditOrganization } from "../../pages/organizations/Edit";
import { NewRecurringEvent } from "../../pages/recurring_events/NewRecurringEvent";
import { EditRecurringEvent } from "../../pages/recurring_events/EditRecurringEvent";
import { EventParentPage } from "../../pages/events/components/EventParentPage";
import { PaymentAccount } from "../../pages/organizations/settings/PaymentAccount";
import { NewWaiver } from "../../pages/waivers/NewWaiver";
import { ShowWaiver } from "../../pages/waivers/Show";
import { EditWaiver } from "../../pages/waivers/Edit";
import { OrganizationCalendar } from "../../pages/organizations/components/OrganizationCalendar";
import { EventPaymentRedirector } from "../../pages/payments/EventPaymentRedirector";
import { Logout } from "../../pages/auth/Logout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/auth",
        element: <PhoneAuth />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/events/:eventId",
        element: <EventParentPage />,
        children: [
          {
            path: "",
            element: <EventShowPage />,
            loader: eventShowPageLoader,
          },
        ],
      },
      {
        element: <AuthenticatedRoutes />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "/events/my",
            element: <MyEventsPage />,
          },
          {
            path: "/events/new",
            element: <NewEventPage />,
          },
          {
            path: "/events/:eventId",
            element: <EventParentPage />,
            children: [
              {
                path: "",
                element: <EventShowPage />,
                loader: eventShowPageLoader,
                children: [{ path: "people" }, { path: "attendance" }],
              },
              {
                path: "edit",
                element: <EditEventPage />,
              },
              {
                path: "payments",
                element: <EventPaymentRedirector />,
                children: [
                  {
                    path: "new",
                    element: <EventPaymentPage />,
                    loader: eventPaymentPageLoader,
                  },
                ],
              },
            ],
          },
          {
            path: "/recurring_events/:id/edit",
            element: <EditRecurringEvent />,
          },
          {
            path: "/organizations/my",
            element: <MyOrganization />,
          },
          {
            path: "organizations/new",
            element: <NewOrganization />,
          },
          {
            path: "/organizations/:organizationId",
            element: <OrganizationParentPage />,
            children: [
              {
                path: "",
                element: <OrganizationShowPage />,
                children: [
                  {
                    path: "waivers/:waiverId",
                    element: <ShowWaiver />,
                  },
                ],
              },
              { path: "edit", element: <EditOrganization /> },
              {
                path: "events/new",
                element: <NewEventPage />,
              },
              {
                path: "recurring_events/new",
                element: <NewRecurringEvent />,
              },
              {
                path: "settings",
                children: [
                  {
                    path: "payment-account",
                    element: <PaymentAccount />,
                  },
                ],
              },
              {
                path: "waivers",
                children: [
                  {
                    path: "new",
                    element: <NewWaiver />,
                  },
                  {
                    path: ":waiverId/edit",
                    element: <EditWaiver />,
                  },
                ],
              },
            ],
          },
          {
            path: "/users/:id",
            element: <UserShowPage />,
            children: [{ path: "friends" }],
          },
          {
            path: "/users/:id/edit",
            element: <UserEditPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/embed",
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "organizations/:organizationId",
        element: <OrganizationParentPage />,
        children: [
          {
            path: "calendar",
            element: <OrganizationCalendar linkExternal={true} />,
          },
        ],
      },
    ],
  },
]);
