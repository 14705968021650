import { useCallback, useEffect, useState } from "react";
import { fetchEvent } from "../services/api/events";
import { VianikoEvent } from "../types/events";

interface UseEvent {
  event: VianikoEvent | null;
  refetch: () => void;
}

export const useEvent = (eventId: string | undefined): UseEvent => {
  const [event, setEvent] = useState<VianikoEvent | null>(null);

  const fetch = useCallback(async () => {
    if (!eventId) return;

    const { event } = await fetchEvent(eventId);
    setEvent(event);
  }, [eventId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { event, refetch: fetch };
};
