import { Avatar } from "@chakra-ui/react";
import { VianikoUser } from "../types/users";
import { getImageUrl } from "../services/images";

interface UserAvatarProps {
  user?: VianikoUser;
  photoId?: string;
  name?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  photoId: photoIdProp,
  name: nameProp,
  size = "md",
}) => {
  const photoId = user?.photo_id || photoIdProp;
  const name = user?.full_name || nameProp;

  const imageUrl = photoId ? getImageUrl(photoId) : "";
  return <Avatar size={size} name={name} src={imageUrl} />;
};
