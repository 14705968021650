import { useCallback, useEffect, useState } from "react";
import * as api from "../services/api";
import { OrganizationUser } from "../types/organization_users";

export type ExtendedOrganizationUser = OrganizationUser & { full_name: string };

interface UseOrganizationUsersResponse {
  users: ExtendedOrganizationUser[];
  refetch: () => void;
}

export const useOrganizationUsers = (
  organizationId?: string
): UseOrganizationUsersResponse => {
  const [users, setUsers] = useState<ExtendedOrganizationUser[]>([]);

  const fetchUsers = useCallback(async () => {
    if (!organizationId) return;
    const users = await api.organizations.fetchOrganizationUsers(
      organizationId
    );
    setUsers(users);
  }, [organizationId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const refetch = async () => {
    await fetchUsers();
  };

  return { users, refetch };
};
