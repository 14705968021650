import { useForm } from "react-hook-form";
import { Waiver } from "../../types/waiver";
import { StyledInput } from "../../components/forms/StyledInput";
import { Button } from "@chakra-ui/react";
import { StyledTextarea } from "../../components/forms/StyledTextarea";

interface WaiverFormProps {
  onSubmit: (waiver: Waiver) => void;
  values?: Waiver;
}

export const WaiverForm: React.FC<WaiverFormProps> = ({
  onSubmit: onSubmitProp,
  values,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Waiver>({ values, mode: "onBlur" });

  const onSubmit = (waiver: Waiver) => {
    onSubmitProp(waiver);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledInput
        name="name"
        label="Give waiver a name"
        type="text"
        register={register}
        options={{ required: "This field is required" }}
        error={errors.name}
      />
      <StyledTextarea
        name="content"
        label="Waiver contents"
        register={register}
        options={{ required: "This field is required" }}
        error={errors.content}
      />
      <Button
        type="submit"
        variant="primary"
        size="lg"
        width="100%"
        marginY={4}
      >
        Save
      </Button>
    </form>
  );
};
