import { useCallback, useEffect, useState } from "react";
import { fetchUserEventsCount } from "../services/api/users";

interface UseUserEventsCount {
  count: number;
  refetch: () => void;
}

export const useUserEventsCount = (
  userId: string | undefined
): UseUserEventsCount => {
  const [count, setCount] = useState<number>(0);

  const fetch = useCallback(async () => {
    if (!userId) return 0;
    const count = await fetchUserEventsCount(userId);
    setCount(count);
  }, [userId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { count, refetch: fetch };
};
