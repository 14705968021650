import axiosInstance from "./axiosInstance";

interface PaymentIntentOptions {
  amountInCents: number;
  numTickets: number;
}

export const getAccount = async (organizationId: string) => {
  const result = await axiosInstance.get(`/payments/${organizationId}/account`);
  return result.data;
};

export const createAccount = async (organizationId: string) => {
  const result = await axiosInstance.post("/payments/account", {
    organization_id: organizationId,
  });
  return result.data;
};

export const accountSession = async (organizationId: string) => {
  const result = await axiosInstance.post("/payments/account-session", {
    organization_id: organizationId,
  });
  return result.data;
};

export const createPaymentIntent = async (
  eventId: string,
  options?: PaymentIntentOptions
) => {
  const { numTickets, amountInCents } = options || {};
  const result = await axiosInstance.post(
    `/payments/events/${eventId}/create-payment-intent`,
    {
      amountInCents,
      numTickets,
    }
  );
  return result.data;
};

export const fetchUnsignedWaivers = async (organizationId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/waivers/unsigned`
  );
  return result.data;
};
