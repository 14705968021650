import {
  Flex,
  Icon,
  IconButton,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { BiDotsHorizontalRounded, BiTrashAlt } from "react-icons/bi";
import { Panel } from "../../../components/Panel";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { FriendRequestsList } from "./FriendRequestsList";
import { removeFriendship } from "../../../services/api/friendships";
import { UseFriendshipFriend } from "../../../hooks/useFriendships";
import { userShowUrl } from "../../../services/routes/urlBuilder";
import { UserAvatar } from "../../../components/UserAvatar";

interface UserFriendsListProps {
  userId: string;
  friends: UseFriendshipFriend[] | undefined;
  onChange: () => void;
}

export const UserFriendsList: React.FC<UserFriendsListProps> = ({
  userId,
  friends,
  onChange,
}) => {
  const { currentUser } = useCurrentUser();

  const handleRemove = (friend: UseFriendshipFriend) => async () => {
    await removeFriendship(friend.id, friend.friendship_id);
    onChange();
  };

  return (
    <>
      {userId === currentUser?.id && <FriendRequestsList onAccept={onChange} />}
      {friends && friends.length > 0 && (
        <Panel>
          <Flex gap={4} direction="column">
            {friends.map((friend) => (
              <LinkBox key={friend.id}>
                <Flex gap={2} align="center">
                  <UserAvatar size="md" user={friend} />
                  <LinkOverlay as={RouterLink} to={userShowUrl(friend.id)}>
                    <Text flexGrow={4} whiteSpace="nowrap" noOfLines={1}>
                      {friend.full_name}
                    </Text>
                  </LinkOverlay>
                  <Spacer />

                  {currentUser?.id === userId && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<Icon as={BiDotsHorizontalRounded} />}
                        variant="ghost"
                      />

                      <MenuList>
                        <Link onClick={handleRemove(friend)}>
                          <MenuItem>
                            <Icon as={BiTrashAlt} marginRight={2} />
                            Remove from friends
                          </MenuItem>
                        </Link>
                      </MenuList>
                    </Menu>
                  )}
                </Flex>
              </LinkBox>
            ))}
          </Flex>
        </Panel>
      )}
    </>
  );
};
