import {
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { OrganizationThemeCard } from "../../organizations/components/OrganizationThemeCard";
import { UserProfileEvent, useUserEvents } from "../../../hooks/useUserEvents";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { updateEventUser } from "../../../services/api/eventUsers";
import { DatetimeDirection } from "../../../types/datetime";
import { FilterButtonMenu } from "../../../components/FilterButtonMenu";
import { useSearchParams } from "react-router-dom";

interface EventsListProps {
  userId: string | undefined;
}

export const EventsList: React.FC<EventsListProps> = ({ userId }) => {
  const { currentUser } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const initialDirection =
    searchParams.get("eventsDirection") === "future" ? "future" : "past";
  const [direction, setDirection] =
    useState<DatetimeDirection>(initialDirection);

  const { events, refetch: refetchEvents } = useUserEvents(userId, direction);

  const handleVisibility = (event: UserProfileEvent) => async () => {
    if (!event.event_user_id) return;
    await updateEventUser(event.event_user_id, { findable: !event.findable });
    await refetchEvents();
  };

  const handleSelectFilter = async (filter: DatetimeDirection) => {
    setDirection(filter);
  };

  return (
    <VStack width="100%" gap={1}>
      {currentUser?.id === userId && (
        <HStack width="100%" paddingX={4}>
          <Text size="lg" marginBottom={4}>
            {direction === "future" ? "Upcoming events" : "Past events"}
          </Text>
          <Spacer />
          <FilterButtonMenu<DatetimeDirection>
            options={[
              { label: "Upcoming", value: "future" },
              { label: "Past", value: "past" },
            ]}
            onSelect={handleSelectFilter}
          />
        </HStack>
      )}

      {events?.map((event) => (
        <OrganizationThemeCard
          key={event.id}
          theme={event.theme}
          organizationName={event.organization_name}
          organizationId={event.organization_id}
          photoId={event.photo_id}
          event={event}
          redirectTo="event"
          renderTopRight={() =>
            currentUser && currentUser.id === event.user_id ? (
              <Tooltip
                label={
                  event.findable ? "Visible to eveeryone" : "Visible to just me"
                }
              >
                <IconButton
                  variant="ghost"
                  onClick={handleVisibility(event)}
                  icon={
                    event.findable ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )
                  }
                  aria-label={
                    event.findable
                      ? "Visible to everyone"
                      : "Visible to just me"
                  }
                  fontSize="32px"
                  color="gray.800"
                />
              </Tooltip>
            ) : null
          }
        />
      ))}
    </VStack>
  );
};
