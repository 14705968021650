import { Box, Text } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

export const ErrorPage: React.FC = () => {
  const error: any = useRouteError();

  let errorMessage = "Something has gone wrong. Please try again later.";
  if (error?.response?.status === 401) {
    errorMessage = "Unauthorized";
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Text fontSize="2xl">{errorMessage}</Text>
    </Box>
  );
};
