import { Waiver } from "../../types/waiver";
import axiosInstance from "./axiosInstance";

export const fetchWaivers = async (organizationId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/waivers`
  );
  return result.data;
};

export const fetchWaiver = async (organizationId: string, waiverId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/waivers/${waiverId}`
  );
  return result.data;
};

export const createWaiver = async (organizationId: string, waiver: Waiver) => {
  const result = await axiosInstance.post(
    `/organizations/${organizationId}/waivers`,
    waiver
  );
  return result.data;
};

export const updateWaiver = async (organizationId: string, waiver: Waiver) => {
  const { id, ...data } = waiver;
  const result = await axiosInstance.put(
    `/organizations/${organizationId}/waivers/${id}`,
    data
  );
  return result.data;
};

export const deleteWaiver = async (
  organizationId: string,
  waiverId: string
) => {
  const result = await axiosInstance.delete(
    `/organizations/${organizationId}/waivers/${waiverId}`
  );
  return result.data;
};

export const createWaiverSignature = async (
  organizationId: string,
  waiverId: string
) => {
  const result = await axiosInstance.post(
    `/organizations/${organizationId}/waivers/${waiverId}/waiver_signatures`
  );
  return result.data;
};

export const fetchWaiverSignature = async (
  organizationId: string,
  waiverId: string
) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/waivers/${waiverId}/waiver_signature`
  );
  return result.data;
};
