import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderRadius: "8px",
    img: {
      borderRadius: "8px",
    },
  },
});

const sizes = {
  md: definePartsStyle({
    container: {
      width: "40px",
      height: "40px",
    },
  }),
  "2xl": definePartsStyle({
    container: {
      width: "200px",
      height: "200px",
      borderRadius: "24px",
      img: {
        borderRadius: "24px",
      },
    },
  }),
};

export const avatarTheme = defineMultiStyleConfig({ baseStyle, sizes });
