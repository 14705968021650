import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import { IconType } from "react-icons";
import { BiCalendar, BiDollarCircle } from "react-icons/bi";
import { MdOutlineWatchLater } from "react-icons/md";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import { ThemeCard } from "../../../components/ThemeCard";
import { VianikoEvent } from "../../../types/events";
import { Organization } from "../../../types/organization";
import { useCommunityTheme } from "../../../hooks/useCommunityTheme";
import { priceInDollars } from "../../../services/payments";
import { organizationShowUrl } from "../../../services/routes/urlBuilder";
import { getImageUrl } from "../../../services/images";

interface EventThemeCardProps {
  event: VianikoEvent;
  organization?: Organization;
}

export const EventThemeCard: React.FC<EventThemeCardProps> = ({
  event,
  organization,
}) => {
  const { primaryColor, complementaryColor } = useCommunityTheme(
    organization?.theme
  );

  const infoSections: {
    icon: IconType;
    title: string | undefined;
    value: string;
  }[] = useMemo(() => {
    return [
      {
        icon: BiCalendar,
        title: format(new Date(event.start_at), "MMMM d"),
        value: format(new Date(event.start_at), "EEEE"),
      },
      {
        icon: MdOutlineWatchLater,
        title: format(new Date(event.start_at), "h:mm a"),
        value: "til " + format(event.end_at, "h:mm a"),
      },
      {
        icon: BiDollarCircle,
        title:
          event.payment_type === "fixed"
            ? priceInDollars(event.price_in_cents)
            : event.payment_type === "slider" && event.min_price_in_cents
            ? `From ${priceInDollars(event.min_price_in_cents, 0)}`
            : "Free",
        value:
          event.payment_type === "slider" && event.max_price_in_cents
            ? `to ${priceInDollars(event.max_price_in_cents, 0)}`
            : "",
      },
    ];
  }, [event]);

  return (
    <ThemeCard theme={organization?.theme} height="400px">
      <Box height="50%">
        <Flex>
          <Box width="50%" paddingTop={4}>
            {organization && (
              <Link as={RouterLink} to={organizationShowUrl(organization.id)}>
                <Text size="sm" color={complementaryColor} marginLeft={5}>
                  {organization.name}
                </Text>
              </Link>
            )}
          </Box>
          <Box width="50%">
            {event.photo_ids?.length ? (
              <Image
                src={getImageUrl(event.photo_ids[0])}
                width="100%"
                height="200px"
                fit="cover"
                borderRadius="24px"
              />
            ) : null}
          </Box>
        </Flex>

        <Heading
          position="absolute"
          as="h1"
          size="xl"
          marginBottom={8}
          maxWidth="320px"
          color={primaryColor}
          top="104px"
          left={5}
        >
          {event.name.split(" ").map((word, index) => (
            <Box as="span" key={index} backgroundColor={complementaryColor}>
              {word + " "}
            </Box>
          ))}
        </Heading>
      </Box>
      <Box height="50%">
        <Flex>
          <Box width="50%">
            <VStack marginLeft={5} marginTop={4} gap={4} align="start">
              {infoSections.map(
                (section) =>
                  section.title && (
                    <HStack align="start" key={section.title}>
                      <Icon as={section.icon} color={complementaryColor} />
                      <Box>
                        <Text size="md" color={complementaryColor}>
                          {section.title}
                        </Text>
                        <Text size="sm" color={`${complementaryColor}80`}>
                          {section.value}
                        </Text>
                      </Box>
                    </HStack>
                  )
              )}
            </VStack>
          </Box>
          <Box width="50%"></Box>
        </Flex>
      </Box>
    </ThemeCard>
  );
};
