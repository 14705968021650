import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  stepperGroup: {
    display: "flex",
    flexDir: "row",
    width: "68px",
    height: "100%",
    marginRight: 2,
  },
  stepper: {
    border: "none",
  },
});

export const numberInputTheme = defineMultiStyleConfig({
  baseStyle,
});
