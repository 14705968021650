import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: "24px",
    _focus: {
      border: "2px dashed black",
    },
    _focusVisible: {},
  },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
