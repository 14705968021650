import { useEffect, useState } from "react";
import { Organization } from "../../../types/organization";
import { OrganizationThemeCard } from "./OrganizationThemeCard";
import { VianikoEvent } from "../../../types/events";
import { fetchNextEvent } from "../../../services/api/organizations";

interface OrganizationThemeCardProps {
  organization: Organization;
}

export const OrganizationListThemeCard: React.FC<
  OrganizationThemeCardProps
> = ({ organization }) => {
  const [event, setEvent] = useState<VianikoEvent>();

  useEffect(() => {
    const fetch = async () => {
      const event = await fetchNextEvent(organization.id);
      setEvent(event);
    };
    fetch();
  }, [organization]);

  return (
    <OrganizationThemeCard
      theme={organization.theme}
      organizationName={organization.name}
      organizationId={organization.id}
      event={event}
      photoId={organization.photo_id}
    />
  );
};
