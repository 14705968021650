import { OrganizationUserStatus } from "../../types/organization_users";
import axiosInstance from "./axiosInstance";

export const fetchCurrentOrganizationUser = async (orgId: string) => {
  const result = await axiosInstance.get(`/organization_users/${orgId}/my`);
  return result.data;
};

export const create = async (orgId: string) => {
  const result = await axiosInstance.post(`/organization_users`, {
    organization_id: orgId,
  });
  return result.data;
};

export const updateOrganizationUserStatus = async (
  id: string,
  status: OrganizationUserStatus
) => {
  const result = await axiosInstance.put(`/organization_users/${id}`, {
    status,
  });
  return result.data;
};

export const searchOrganizationUsers = async (
  organizationId: string,
  query: string
) => {
  const result = await axiosInstance.get(
    `/organization_users/${organizationId}/search`,
    {
      params: { query },
    }
  );
  return result.data;
};
