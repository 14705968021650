import { useNavigate, useParams } from "react-router-dom";
import { Waiver } from "../../types/waiver";
import { organizationShowUrl } from "../../services/routes/urlBuilder";
import { WaiverForm } from "./WaiverForm";
import { useEffect, useState } from "react";
import {
  deleteWaiver,
  fetchWaiver,
  updateWaiver,
} from "../../services/api/waivers";
import { DeleteButtonConfirmation } from "../../components/DeleteButtonConfirmation";

export const EditWaiver: React.FC = () => {
  const [waiver, setWaiver] = useState<Waiver>();
  const { organizationId, waiverId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      if (!organizationId || !waiverId) return;

      const result = await fetchWaiver(organizationId, waiverId);
      setWaiver(result);
    };

    fetch();
  }, [organizationId, waiverId]);

  const handleSubmit = async (waiver: Waiver) => {
    if (!organizationId || !waiverId) return;

    await updateWaiver(organizationId, waiver);

    navigate(organizationShowUrl(organizationId));
  };

  const handleDelete = async () => {
    if (!organizationId || !waiverId) return;

    await deleteWaiver(organizationId, waiverId);

    navigate(organizationShowUrl(organizationId));
  };

  return (
    <>
      <WaiverForm onSubmit={handleSubmit} values={waiver} />
      <DeleteButtonConfirmation onConfirm={handleDelete} />
    </>
  );
};
