import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { Control, Controller, FieldError } from "react-hook-form";
import { isValid } from "date-fns";

interface StyledDateTimeInputProps {
  name: string;
  control: Control<any>;
  defaultValue?: string;
  label?: string;
  error?: FieldError;
  rules?: any;
  timeOnly?: boolean;
}

const isoToDate = (iso: string) => {
  const date = new Date(iso);
  const year = date.getFullYear().toString().padStart(4, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const value = `${year}-${month}-${day}`;
  return value;
};

const isoToTime = (iso: string) => {
  const date = new Date(iso);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const value = `${hours}:${minutes}`;

  return value;
};

// data is of the format { date: string, time: string }
export const StyledDateTimeInput: React.FC<StyledDateTimeInputProps> = ({
  name,
  label,
  defaultValue,
  timeOnly = false,
  control,
  error,
  rules,
}) => {
  const timezoneOffset = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset / 60));
    const minutes = Math.abs(offset % 60);
    const sign = offset < 0 ? "+" : "-";
    return `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup width="100%">
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field }) => (
            <>
              <Input
                name={`${name}.date`}
                type="date"
                onChange={(e) => {
                  if (!e.target.value) return;

                  const timestamp = `${e.target.value}T${
                    e.target.value
                      ? isoToTime(e.target.value)
                      : isoToTime(new Date().toISOString())
                  }${timezoneOffset()}`;

                  if (isValid(new Date(timestamp))) {
                    field.onChange(timestamp);
                  }
                }}
                value={(() => {
                  return isoToDate(field.value);
                })()}
                visibility={timeOnly ? "hidden" : "visible"}
                width={timeOnly ? 0 : 48}
                paddingX={timeOnly ? 0 : 4}
                marginRight={timeOnly ? 0 : 4}
                backgroundColor="white"
                border="2px solid"
              />
              <Input
                name={`${name}.time`}
                type="time"
                onChange={(e) => {
                  const timestamp = `${
                    field.value
                      ? isoToDate(field.value)
                      : isoToDate(new Date().toISOString())
                  }T${e.target.value}${timezoneOffset()}`;

                  if (isValid(new Date(timestamp))) {
                    field.onChange(timestamp);
                  }
                }}
                value={(() => {
                  return isoToTime(field.value);
                })()}
                width={48}
                backgroundColor="white"
                border="2px solid"
              />
            </>
          )}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
