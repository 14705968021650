import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import {
  FieldError,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

export interface AutocompleteOption {
  id: string;
  label: string;
}

interface AutocompleteInputProps {
  name: string;
  label: string;
  selectOptions: AutocompleteOption[];
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  register: UseFormRegister<any>;
  error?: FieldError;
  registerOptions?: RegisterOptions;
  setValue?: UseFormSetValue<any>;
  defaultValue?: string;
  clearErrors?: UseFormClearErrors<any>;
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  name,
  label,
  selectOptions,
  onKeyUp,
  register,
  error,
  registerOptions,
  setValue,
  defaultValue,
  clearErrors,
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <AutoComplete
        openOnFocus
        onChange={(val) => {
          clearErrors && clearErrors(name);
          setValue && setValue(name, val);
        }}
        defaultValue={defaultValue}
      >
        <AutoCompleteInput
          {...register(name, registerOptions)}
          onKeyUp={onKeyUp}
          backgroundColor="white"
          border="2px solid"
        />
        <AutoCompleteList>
          {selectOptions.map((selectOption) => (
            <AutoCompleteItem
              key={selectOption.id}
              value={selectOption.id}
              label={selectOption.label}
            >
              {selectOption.label}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
