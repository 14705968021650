import { useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

export const Logout: React.FC = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      await signOut();
    };
    init();
  }, [signOut, navigate]);

  return null;
};
