import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { MENU_BACKGROUND_COLOR } from "../colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    borderRadius: "24px",
    bg: MENU_BACKGROUND_COLOR,
    border: "none",
  },
  item: {
    bg: MENU_BACKGROUND_COLOR,
    borderRadius: "24px",
  },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
