import { Control, FieldErrors, useController } from "react-hook-form";
import { VianikoEvent } from "../../../../types/events";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { DollarsInput } from "../../../../components/forms/DollarsInput";
import { Organization } from "../../../../types/organization";

interface PaymentConfigurationFormSectionProps {
  control: Control<VianikoEvent>;
  errors?: FieldErrors<VianikoEvent>;
  organization?: Organization;
}

export const PaymentConfigurationFormSection: React.FC<
  PaymentConfigurationFormSectionProps
> = ({ control, errors, organization }) => {
  const {
    field: { onChange, onBlur, value: paymentTypeValue, name, ref },
  } = useController({
    name: "payment_type",
    defaultValue: "free",
    control,
    rules: { required: "Payment type is required" },
  });

  return (
    <>
      <FormControl isInvalid={!!errors?.payment_type} width="100%">
        <FormLabel htmlFor={"payment_type"}>Payment Type</FormLabel>
        <Tooltip
          label={
            !organization
              ? "Create a feather in order to offer paid events"
              : organization.stripe_account_id === null
              ? "Setup your payment account in order to offer paid events"
              : ""
          }
        >
          <Select
            placeholder="Payment type"
            isInvalid={!!errors?.payment_type}
            isDisabled={
              !organization || organization.stripe_account_id === null
            }
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            value={paymentTypeValue}
            backgroundColor="white"
            border="2px solid"
            borderRadius="24px"
          >
            <option value="free">Free</option>
            <option value="fixed">Paid</option>
            <option value="slider">Sliding scale</option>
          </Select>
        </Tooltip>
        {errors?.payment_type && (
          <FormErrorMessage>{errors?.payment_type.message}</FormErrorMessage>
        )}
      </FormControl>
      {paymentTypeValue === "fixed" ? (
        <Box marginTop={4}>
          <DollarsInput
            name="price_in_cents"
            defaultValue={1000}
            label="Price"
            control={control}
          />
        </Box>
      ) : paymentTypeValue === "slider" ? (
        <VStack gap={4} marginTop={4}>
          <DollarsInput
            name="min_price_in_cents"
            label="Minimum price"
            defaultValue={1000}
            control={control}
          />
          <DollarsInput
            name="max_price_in_cents"
            label="Maximum price"
            defaultValue={2000}
            control={control}
          />
          <DollarsInput
            name="price_in_cents"
            label="Default price"
            defaultValue={1500}
            control={control}
          />
        </VStack>
      ) : null}
    </>
  );
};
