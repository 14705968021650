import { AddIcon } from "@chakra-ui/icons";
import { ButtonLink } from "../../../components/ButtonLink";
import { HStack, Text } from "@chakra-ui/react";
import { eventNewUrl } from "../../../services/routes/urlBuilder";

export const MyOrganizationCalendar: React.FC = () => {
  return (
    <>
      <ButtonLink to={eventNewUrl()}>
        <HStack>
          <AddIcon />
          <Text>Add Event</Text>
        </HStack>
      </ButtonLink>
    </>
  );
};
