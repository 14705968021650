import { Outlet, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useCurrentOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import { OrganizationCalendar } from "./components/OrganizationCalendar";
import { RecurringEventsList } from "../recurring_events/List";
import {
  eventNewUrl,
  homeUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import { JoinCommunityButton } from "./components/JoinCommunityButton";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";
import { WaiversList } from "./components/WaiversList";
import { OrganizationThemeCard } from "./components/OrganizationThemeCard";
import { ManageOrganizationMenuListItems } from "./components/ManageOrganizationMenuListItems";
import {
  deleteOrganization,
  inviteUserToOrganization,
} from "../../services/api/organizations";
import { ButtonLink } from "../../components/ButtonLink";
import { BiCalendarPlus, BiShareAlt } from "react-icons/bi";
import { OrganizationMembers } from "./components/OrganizationMembers";
import { useOrganizationUsers } from "../../hooks/useOrganizationUsers";
import { useWaivers } from "../../hooks/useWaivers";
import { PageHeader } from "../../components/PageHeader";
import { OrganizationInfo } from "./components/OrganizationInfo";
import { InviteFriendsModal } from "../../components/InviteFriendsModal";
import { useState } from "react";

export const OrganizationShowPage: React.FC = () => {
  const [isInviteFriendsModalOpen, setIsInviteFriendsModalOpen] =
    useState(false);
  const { organization } = useCurrentOrganization();
  const { currentOrganizationUser, refetch: refetchCurrentOrganizationUser } =
    useCurrentOrganizationUser();
  const { users: organizationUsers, refetch: refetchOrgUsers } =
    useOrganizationUsers(organization?.id);
  const { waivers } = useWaivers(organization?.id);

  const navigate = useNavigate();

  const onJoin = () => {
    refetchCurrentOrganizationUser();
  };

  const handleDelete = async () => {
    if (!organization) return;

    await deleteOrganization(organization.id);
    navigate(homeUrl());
  };

  const handleUpdateMember = async () => {
    await refetchOrgUsers();
  };

  const handleLeave = async () => {
    if (!currentOrganizationUser) return;
    await refetchCurrentOrganizationUser();
  };

  if (!organization) return null;

  const handleInvite = async (userIds: string[]) => {
    await Promise.all(
      userIds.map((userId) => inviteUserToOrganization(organization.id, userId))
    );
  };

  return (
    <>
      <InviteFriendsModal
        isOpen={isInviteFriendsModalOpen}
        inviteLink={`${process.env.REACT_APP_BASE_URL}${organizationShowUrl(
          organization.id
        )}`}
        onClose={() => setIsInviteFriendsModalOpen(false)}
        onInvite={handleInvite}
      />

      <PageHeader
        backLink={homeUrl()}
        menuListContent={
          currentOrganizationUser ? (
            <ManageOrganizationMenuListItems
              organizationId={organization.id}
              onDelete={handleDelete}
              onLeave={handleLeave}
            />
          ) : null
        }
      />
      <OrganizationThemeCard
        theme={organization.theme}
        organizationName={organization.name}
        organizationId={organization.id}
        photoId={organization.photo_ids?.[0]}
      />

      <Flex gap={1} marginTop={1}>
        {currentOrganizationUser ? (
          <>
            <Button
              variant="primary"
              flexGrow={1}
              onClick={() => setIsInviteFriendsModalOpen(true)}
            >
              <Icon as={BiShareAlt} marginRight={2} />
              <Heading as="h3" size="sm">
                Invite a friend
              </Heading>
            </Button>
          </>
        ) : (
          <JoinCommunityButton
            organizationId={organization.id}
            onComplete={onJoin}
            flexGrow={1}
          />
        )}
        {currentOrganizationUser?.is_owner && (
          <ButtonLink
            to={eventNewUrl(organization.id)}
            buttonProps={{ variant: "primary" }}
          >
            <HStack>
              <Icon as={BiCalendarPlus} />
              <Heading size="sm">Add Event</Heading>
            </HStack>
          </ButtonLink>
        )}
      </Flex>

      <Tabs>
        <TabList marginTop={2}>
          <Tab>Info</Tab>
          <Tab>Sign up</Tab>
          {currentOrganizationUser?.is_owner && (
            <Tab>{`${organizationUsers.length} ${
              organizationUsers.length === 1 ? "Member" : "Members"
            }`}</Tab>
          )}
          {currentOrganizationUser?.is_owner && <Tab>Recurring Events</Tab>}
          {currentOrganizationUser?.is_owner ||
          (currentOrganizationUser && waivers && waivers.length > 0) ? (
            <Tab>{`${
              waivers && waivers.length > 1
                ? `${waivers.length} waivers`
                : "Waiver"
            }`}</Tab>
          ) : null}
        </TabList>

        <TabPanels>
          <TabPanel>
            <OrganizationInfo organization={organization} />
          </TabPanel>
          <TabPanel>
            <OrganizationCalendar />
          </TabPanel>
          {currentOrganizationUser?.is_owner && (
            <TabPanel>
              <OrganizationMembers
                organizationUsers={organizationUsers}
                onUpdateStatus={handleUpdateMember}
              />
            </TabPanel>
          )}
          {currentOrganizationUser?.is_owner && (
            <TabPanel>
              <RecurringEventsList organizationId={organization.id} />
            </TabPanel>
          )}
          {currentOrganizationUser && (
            <TabPanel>
              <WaiversList organizationId={organization.id} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <Outlet />
    </>
  );
};
