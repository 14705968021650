import { Flex, Image, Text } from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { Organization } from "../../../types/organization";
import { getImageUrl } from "../../../services/images";
import { getHtmlFromString } from "../../../services/formatter";

interface OrganizationInfoProps {
  organization: Organization;
}

export const OrganizationInfo: React.FC<OrganizationInfoProps> = ({
  organization,
}) => {
  const [, ...photoIds] = organization.photo_ids || [];

  const html = getHtmlFromString(organization.description);

  return (
    <>
      <Panel>
        <Text
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></Text>
      </Panel>

      <Flex flexWrap="wrap" gap={1}>
        {photoIds.map((photoId) => (
          <Image
            key={photoId}
            src={getImageUrl(photoId)}
            alt={organization.name}
            width="calc(50% - 2px)"
            height="200px"
            fit="cover"
            borderRadius="24px"
          />
        ))}
      </Flex>
    </>
  );
};
