import {
  Box,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useCommunityTheme } from "../../../hooks/useCommunityTheme";
import { OrganizationTheme } from "../../../types/organization";
import { ThemeCard } from "../../../components/ThemeCard";
import {
  eventShowUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";
import { VianikoEvent } from "../../../types/events";
import {
  formatDateOrganizationNextEvent,
  formatDatePastEvent,
} from "../../../services/datetime";
import { isPast } from "date-fns";
import { getImageUrl } from "../../../services/images";
import React from "react";

interface OrganizationThemeCardProps {
  theme: OrganizationTheme;
  organizationName: string;
  organizationId: string;
  photoId?: string;
  event?: VianikoEvent;
  redirectTo?: "event" | "organization";
  renderTopRight?: (
    primaryColor: string,
    complementaryColor: string
  ) => React.ReactNode;
}

export const OrganizationThemeCard: React.FC<OrganizationThemeCardProps> = ({
  theme,
  organizationName,
  organizationId,
  photoId,
  event,
  redirectTo = "organization",
  renderTopRight,
}) => {
  const { primaryColor, complementaryColor } = useCommunityTheme(theme);

  return (
    <LinkBox width="100%">
      <ThemeCard theme={theme}>
        <Flex height="100%">
          <Box width="50%">
            <Heading
              size="xl"
              color={complementaryColor}
              marginY={4}
              marginX={5}
            >
              <LinkOverlay
                as={RouterLink}
                to={
                  redirectTo === "event" && event
                    ? eventShowUrl(event.id)
                    : organizationShowUrl(organizationId)
                }
              >
                {organizationName}
              </LinkOverlay>
            </Heading>
          </Box>
          <Box
            width="50%"
            backgroundImage={photoId ? `url("${getImageUrl(photoId)}")` : ""}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="center"
            borderRadius="24px"
          >
            {event && (
              <Flex height="100%" flexDir="column" padding={4}>
                <Flex width="100%" justifyContent="end">
                  <Box>
                    {renderTopRight &&
                      renderTopRight(primaryColor, complementaryColor)}
                  </Box>
                </Flex>
                <Spacer />
                <Box textAlign="right">
                  <Text
                    as="span"
                    size="sm"
                    color={`${primaryColor}80`}
                    backgroundColor={complementaryColor}
                    paddingX="2px"
                  >
                    {isPast(new Date(event.start_at))
                      ? formatDatePastEvent(new Date(event.start_at))
                      : formatDateOrganizationNextEvent(
                          new Date(event.start_at)
                        )}
                    <br />
                  </Text>
                  {event?.name.split(" ").map((word, index) => (
                    <Text
                      key={index}
                      as={"span"}
                      size="md"
                      color={primaryColor}
                      backgroundColor={complementaryColor}
                      paddingX="2px"
                      wordBreak="break-word"
                    >
                      {word}
                    </Text>
                  ))}
                </Box>
              </Flex>
            )}
          </Box>
        </Flex>
      </ThemeCard>
    </LinkBox>
  );
};
