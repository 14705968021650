import { MenuItem, Link, Icon, Text, Portal } from "@chakra-ui/react";
import {
  BiDoorOpen,
  BiMoneyWithdraw,
  BiPencil,
  BiTrashAlt,
} from "react-icons/bi";
import { NavLink } from "react-router-dom";
import {
  organizationEditUrl,
  organizationManagePaymentAccountUrl,
} from "../../../services/routes/urlBuilder";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { useState } from "react";
import { deleteOrganization } from "../../../services/api/organizations";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { updateOrganizationUserStatus } from "../../../services/api/organizationUsers";

interface ManageOrganizationMenuListItemsProps {
  organizationId: string;
  onDelete: () => void;
  onLeave: () => void;
}

export const ManageOrganizationMenuListItems: React.FC<
  ManageOrganizationMenuListItemsProps
> = ({ organizationId, onDelete, onLeave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const handleDelete = async () => {
    await deleteOrganization(organizationId);
    setIsOpen(false);
    await onDelete();
  };

  const handleLeave = async () => {
    if (!currentOrganizationUser) return;
    await updateOrganizationUserStatus(currentOrganizationUser.id, "inactive");
    onLeave();
  };

  return (
    <>
      {currentOrganizationUser?.is_owner ? (
        <>
          <Link as={NavLink} to={organizationEditUrl(organizationId)}>
            <MenuItem>
              <Icon as={BiPencil} marginRight={2} />
              <Text size="md">Edit feather</Text>
            </MenuItem>
          </Link>
          <Link onClick={() => setIsOpen(true)}>
            <MenuItem>
              <Icon as={BiTrashAlt} marginRight={2} />
              <Text size="md">Delete feather</Text>
            </MenuItem>
          </Link>
          <Link
            as={NavLink}
            to={organizationManagePaymentAccountUrl(organizationId)}
          >
            <MenuItem>
              <Icon as={BiMoneyWithdraw} marginRight={2} />
              <Text size="md">Manage payout</Text>
            </MenuItem>
          </Link>
        </>
      ) : (
        <Link onClick={handleLeave}>
          <MenuItem>
            <Icon as={BiDoorOpen} marginRight={2} />
            <Text size="md">Leave feather</Text>
          </MenuItem>
        </Link>
      )}

      <Portal>
        <DeleteConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onDelete={handleDelete}
        />
      </Portal>
    </>
  );
};
