import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

export const tabsTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    tablist: {
      borderBottomWidth: "0px !important",
      paddingTop: "4px",
      paddingBottom: "12px",
      overflowX: "auto",
      whiteSpace: "nowrap",
    },
    tab: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      marginLeft: "16px",
      marginRight: "16px",
      _selected: {
        color: "black",
      },
      _active: {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
    },
    tabpanel: {
      padding: 0,
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
});
