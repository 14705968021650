import { Button, ButtonProps, StyleProps } from "@chakra-ui/react";
import { useState } from "react";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";

interface DeleteButtonConfirmationProps extends StyleProps {
  onConfirm: () => Promise<void>;
  buttonProps?: ButtonProps & StyleProps;
  actionText?: string;
}

export const DeleteButtonConfirmation: React.FC<
  DeleteButtonConfirmationProps
> = ({ onConfirm, buttonProps, actionText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onDelete = async () => {
    await onConfirm();
    setIsOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        colorScheme="red"
        {...buttonProps}
      >
        {actionText || "Delete"}
      </Button>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};
