import axiosInstance from "./axiosInstance";

export const uploadImage = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axiosInstance.post("/images/upload", formData);

  return data;
};
