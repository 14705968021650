import { loadStripe, Stripe } from "@stripe/stripe-js";

export const priceInDollars = (priceInCents: number, precision?: number) => {
  return `$${(priceInCents / 100).toFixed(
    precision === undefined ? 2 : precision
  )}`;
};

export const initializeStripeAccount = async (
  connectedAccountId: string
): Promise<Stripe | null> => {
  if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
    throw new Error("REACT_APP_STRIPE_PUB_KEY is required");
  }

  return await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY, {
    stripeAccount: connectedAccountId,
  });
};
