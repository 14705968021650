import { useState } from "react";
import { Button, Text } from "@chakra-ui/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

interface PaymentFormProps {
  returnUrl: string;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({ returnUrl }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<string>();
  const stripe = useStripe();

  const elements = useElements();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setSubmitting(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });
    setSubmitting(false);

    if (result.error) {
      setStripeError(result.error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{ layout: "tabs" }} />
      {stripeError && <Text color="red.700">{stripeError}</Text>}
      <Button
        type="submit"
        variant="primary"
        width="100%"
        size="lg"
        marginTop={4}
        marginBottom={12}
        disabled={!stripe || submitting}
      >
        Submit
      </Button>
    </form>
  );
};
