import { Control, useController } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";

interface DollarsInputProps {
  name: string;
  label: string;
  control: Control<any>;
  defaultValue?: number;
}

const INPUT_PATTERN = /^[$]?[0-9.]*$/;

const parseDollar = (val: string) => {
  if (INPUT_PATTERN.test(val)) {
    return Number(val.replace(/[$.]/g, ""));
  }
};

const formatDollar = (val: string) => {
  return `$${(Number(val) / 100).toFixed(2)}`;
};

export const DollarsInput: React.FC<DollarsInputProps> = ({
  name,
  label,
  control,
  defaultValue,
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || 0,
    rules: {
      min: { value: 500, message: "Must be at least $5" },
      required: "This field is required",
    },
  });

  return (
    <FormControl isInvalid={!!error} width="100%">
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Input
        type="text"
        variant="primary"
        onChange={(e) => {
          const nextValue = parseDollar(e.target.value);
          onChange(nextValue);
        }}
        value={formatDollar(value)}
        {...inputDefaultThemeProps}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
