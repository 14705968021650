import React from "react";
import { VianikoEvent } from "../../../../types/events";
import {
  Box,
  Circle,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { BiDollarCircle } from "react-icons/bi";

interface SlidingPriceProps extends StyleProps {
  event: VianikoEvent;
  onChange: (price: number) => void;
}

export const SlidingPrice: React.FC<SlidingPriceProps> = ({
  event,
  onChange,
  ...styleProps
}) => {
  const handleChange = (value: number) => {
    onChange(value);
  };

  return (
    <Box {...styleProps}>
      <Slider
        defaultValue={event.price_in_cents}
        min={event.min_price_in_cents || 0}
        max={event.max_price_in_cents || 10000}
        step={100}
        onChange={handleChange}
        colorScheme="blackAlpha"
        aria-label="sliding-price"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} bgColor="gray.200">
          <Circle size={6} backgroundColor="white" border="2px solid black">
            <Icon as={BiDollarCircle} fontSize="18px" />
          </Circle>
        </SliderThumb>
      </Slider>

      {event.payment_description && <Text>{event.payment_description}</Text>}
    </Box>
  );
};
