import corporateDarkTheme from "../assets/themes/corporate-dark.svg";
import corporateTheme from "../assets/themes/corporate.svg";
import healthTheme from "../assets/themes/health.svg";
import natureTheme from "../assets/themes/nature.svg";
import nightclubTheme from "../assets/themes/nightclub.svg";
import sportTheme from "../assets/themes/sport.svg";
import {
  DEFAULT_THEME_COMPLEMENTARY_COLOR,
  DEFAULT_THEME_PRIMARY_COLOR,
} from "../services/theme/colors";
import { OrganizationTheme } from "../types/organization";

interface UseOrganizationThemeResult {
  backgroundImage: string;
  primaryColor: string;
  complementaryColor: string;
}

export const useCommunityTheme = (
  theme?: OrganizationTheme
): UseOrganizationThemeResult => {
  switch (theme) {
    case "corporate":
      return {
        backgroundImage: corporateTheme,
        primaryColor: "#F6FDFF",
        complementaryColor: "#303132",
      };
    case "corporate-dark":
      return {
        backgroundImage: corporateDarkTheme,
        primaryColor: "#202324",
        complementaryColor: "#EFF9FB",
      };
    case "health":
      return {
        backgroundImage: healthTheme,
        primaryColor: "#FFEFC5",
        complementaryColor: "#007B84",
      };
    case "nature":
      return {
        backgroundImage: natureTheme,
        primaryColor: "#027128",
        complementaryColor: "#E8EA83",
      };
    case "nightlife":
      return {
        backgroundImage: nightclubTheme,
        primaryColor: "#110271",
        complementaryColor: "#08F3D7",
      };
    case "sports":
      return {
        backgroundImage: sportTheme,
        primaryColor: "#271B12",
        complementaryColor: "#F8C820",
      };
    default:
      return {
        backgroundImage: "none",
        primaryColor: DEFAULT_THEME_COMPLEMENTARY_COLOR,
        complementaryColor: DEFAULT_THEME_PRIMARY_COLOR,
      };
  }
};
