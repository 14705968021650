// Base
export const TEXT_PRIMARY_COLOR = "rgba(0, 0, 0, 0.8)";
export const TEXT_SECONDARY_COLOR = "rgba(0, 0, 0, 0.5)";
export const TEXT_TERTIARY_COLOR = "rgba(0, 0, 0, 0.2)";

export const ALERT_RED_COLOR = "#EC2F2F";

export const PRIMARY_HOVER_COLOR = "#E5E5E5";
export const GHOST_HOVER_COLOR = "rgba(0, 0, 0, 0.1)";

export const BACKGROUND_GRADIENT =
  "radial-gradient(190% 87% at -75% 49%, #7EEECB 40%, #EAFCBA 100%)";

// Menu
export const MENU_BACKGROUND_COLOR = "#9ED518";

// Organization Themes
export const DEFAULT_THEME_PRIMARY_COLOR = "#000000";
export const DEFAULT_THEME_COMPLEMENTARY_COLOR = "#F0FFC5";
export const NIGHTCLUB_PRIMARY_COLOR = "#110271";
export const NIGHTCLUB_COMPLEMENTARY_COLOR = "#08F3D7";
export const SPORT_PRIMARY_COLOR = "#271B12";
export const SPORT_COMPLEMENTARY_COLOR = "#F8C820";
export const HEALTH_PRIMARY_COLOR = "#FFEFC5";
export const HEALTH_COMPLEMENTARY_COLOR = "#007B84";
export const NATURE_PRIMARY_COLOR = "#027128";
export const NATURE_COMPLEMENTARY_COLOR = "#E8EA83";
export const CORPORATE_PRIMARY_COLOR = "#F6FDFF";
export const CORPORATE_COMPLEMENTARY_COLOR = "#303132";
export const CORPORATE_DARK_PRIMARY_COLOR = "#202324";
export const CORPORATE_DARK_COMPLEMENTARY_COLOR = "#EFF9FB";

// My Organization
export const EMPTY_CARD_BORDER_COLOR = "rgba(0, 0, 0, 0.1)";
