import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import * as api from "../services/api";
import { EventUser } from "../types/event_users";

interface EventUserContext {
  currentEventUser: EventUser | undefined;
  refetch: () => void;
}
const CurrentEventUserContext = createContext<EventUserContext>({
  currentEventUser: undefined,
  refetch: () => {},
});

interface CurrentEventUserProviderProps {
  eventId: string;
  children: React.ReactNode;
}

export const CurrentEventUserProvider = ({
  eventId,
  children,
}: CurrentEventUserProviderProps) => {
  const { user: authUser } = useAuth();
  const [eventUser, setEventUser] = useState<EventUser | undefined>(undefined);

  const fetchEventUser = useCallback(async () => {
    if (authUser) {
      const user = await api.eventUsers.fetchCurrentEventUser(eventId);
      setEventUser(user);
    } else {
      setEventUser(undefined);
    }
  }, [authUser, eventId]);

  useEffect(() => {
    fetchEventUser();
  }, [fetchEventUser]);

  const refetch = async () => {
    fetchEventUser();
  };

  return (
    <CurrentEventUserContext.Provider
      value={{ currentEventUser: eventUser, refetch }}
    >
      {children}
    </CurrentEventUserContext.Provider>
  );
};

export const useCurrentEventUser = (): EventUserContext => {
  return useContext(CurrentEventUserContext);
};
