import {
  LoaderFunction,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as api from "../../services/api";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../types/events";
import { EventOverview } from "./components/EventOverview";
import { EventAttendees } from "./components/EventAttendees";
import { EventAdminMessages } from "./components/EventAdminMessages";
import { useOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import { ButtonLink } from "../../components/ButtonLink";
import {
  homeUrl,
  organizationShowUrl,
  recurringEventEditUrl,
} from "../../services/routes/urlBuilder";
import { useCurrentEventUser } from "../../providers/CurrentEventUserProvider";
import { EventThemeCard } from "./components/EventThemeCard";
import { Organization } from "../../types/organization";
import { PricingForm } from "./components/pricing";
import { ManageEventMenuListItems } from "./ManageEventMenuListItems";
import { useAttendees } from "./hooks/useAttendees";
import { useEffect, useState } from "react";
import { BiShareAlt } from "react-icons/bi";
import { isPast } from "date-fns";
import { PageHeader } from "../../components/PageHeader";
import { EventInviteFriendsModal } from "./components/EventInviteFriendsModal";

interface LoaderData {
  event: VianikoEvent;
  organization: Organization;
  num_tickets_sold: number;
}

export const loader: LoaderFunction = async ({
  params: { eventId },
}): Promise<LoaderData> => {
  if (!eventId) throw new Error("Event ID is required");

  const { event, organization, num_tickets_sold } = await api.events.fetchEvent(
    eventId
  );
  return { event, organization, num_tickets_sold };
};

export const EventShowPage: React.FC = () => {
  const { event, organization, num_tickets_sold } =
    useLoaderData() as LoaderData;
  const { organizationUser } = useOrganizationUser(event.organization_id);
  const { currentEventUser } = useCurrentEventUser();
  const { attendees, refetch: refetchAttendees } = useAttendees(event.id);
  const [isInviteFriendsModalOpen, setIsInviteFriendsModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [searchParams] = useSearchParams();
  const isInvite = !!searchParams.get("invite");

  useEffect(() => {
    if (isInvite) {
      setIsInviteFriendsModalOpen(true);
    }
  }, [isInvite]);

  const isAdmin = !!organizationUser?.is_owner || !!currentEventUser?.is_owner;
  const numTicketsAvailable = event.capacity - num_tickets_sold;
  const defaultIndex = path.match(/\/attendance/) ? 1 : 0;

  const handleDelete = async () => {
    navigate(
      event.organization_id
        ? organizationShowUrl(event.organization_id)
        : homeUrl()
    );
  };

  const handleRefundTickets = async () => {
    await refetchAttendees();
  };

  return (
    <>
      <EventInviteFriendsModal
        event={event}
        isOpen={isInviteFriendsModalOpen}
        onClose={() => setIsInviteFriendsModalOpen(false)}
      />

      <PageHeader
        backLink={
          event.organization_id
            ? organizationShowUrl(event.organization_id)
            : undefined
        }
        menuListContent={
          isAdmin ? (
            <ManageEventMenuListItems
              eventId={event.id}
              onDelete={handleDelete}
            />
          ) : null
        }
      />

      <Box marginBottom={1}>
        <EventThemeCard event={event} organization={organization} />
      </Box>
      {isPast(event.end_at) ? (
        <Button size="lg" variant="primary" isDisabled width="100%">
          This event is over
        </Button>
      ) : currentEventUser?.status === "attending" ? (
        <HStack gap={1}>
          <Button size="lg" variant="primary" isDisabled>
            Joined
          </Button>
          <Button
            size="lg"
            variant="primary"
            flexGrow={1}
            onClick={() => setIsInviteFriendsModalOpen(true)}
          >
            <Icon as={BiShareAlt} marginX={2} />
            <Heading as="h5" size="sm">
              Invite friends
            </Heading>
          </Button>
        </HStack>
      ) : numTicketsAvailable < 1 ? (
        <Button size="lg" variant="primary" isDisabled width="100%">
          Sold out
        </Button>
      ) : (
        <PricingForm event={event} />
      )}

      {isAdmin && (
        <HStack>
          <Spacer />
          {event.recurring_event_id && (
            <ButtonLink
              to={recurringEventEditUrl(event.recurring_event_id)}
              buttonProps={{ variant: "outline", margin: 0 }}
            >
              Edit Recurring Event
            </ButtonLink>
          )}
        </HStack>
      )}

      <Tabs marginY={2} defaultIndex={defaultIndex}>
        <TabList>
          <Tab>Info</Tab>
          {currentEventUser?.status === "attending" && (
            <Tab>{`${attendees?.length} ${
              attendees && attendees.length > 1 ? "attendees" : "attendee"
            }`}</Tab>
          )}
          {isAdmin && <Tab>Messages</Tab>}
        </TabList>

        <TabPanels>
          <TabPanel>
            <EventOverview event={event} />
          </TabPanel>
          {currentEventUser?.status === "attending" && (
            <TabPanel>
              <EventAttendees
                event={event}
                attendees={attendees}
                onRefundTickets={handleRefundTickets}
              />
            </TabPanel>
          )}
          {isAdmin && (
            <TabPanel>
              <EventAdminMessages />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};
