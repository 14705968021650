import {
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { UserAvatar } from "../../../components/UserAvatar";
import { Panel } from "../../../components/Panel";
import { useFriendshipRequests } from "../../events/hooks/useFriendshipRequests";
import { BiDotsHorizontalRounded, BiTrashAlt } from "react-icons/bi";

interface FriendRequestsListProps {
  onAccept: () => void;
}

export const FriendRequestsList: React.FC<FriendRequestsListProps> = ({
  onAccept,
}) => {
  const { accept, ignore, friendshipRequestIncomingByUserId, refetch } =
    useFriendshipRequests();

  const handleAccept = (userId: string) => async () => {
    await accept(userId);
    onAccept();
    await refetch();
  };

  const handleRemove = (userId: string) => async () => {
    await ignore(userId);
    onAccept();
    await refetch();
  };

  return friendshipRequestIncomingByUserId &&
    Object.keys(friendshipRequestIncomingByUserId).length > 0 ? (
    <Panel>
      <Text size="md" marginBottom={4}>
        New friend requests
      </Text>
      <VStack gap={4}>
        {Object.keys(friendshipRequestIncomingByUserId).map((userId) => {
          const friendshipRequest = friendshipRequestIncomingByUserId[userId];
          return (
            <HStack width="100%" key={friendshipRequest.id}>
              <UserAvatar
                size="md"
                name={friendshipRequest.full_name}
                photoId={friendshipRequest.photo_id}
              />
              <Text>{friendshipRequest.full_name}</Text>
              <Spacer />
              <Button
                variant="primary"
                size="md"
                onClick={handleAccept(userId)}
              >
                Add friend
              </Button>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={BiDotsHorizontalRounded} />}
                  variant="ghost"
                />

                <MenuList>
                  <Link onClick={handleRemove(userId)}>
                    <MenuItem>
                      <Icon as={BiTrashAlt} marginRight={2} />
                      Remove request
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            </HStack>
          );
        })}
      </VStack>
    </Panel>
  ) : null;
};
