import { Box, Container } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppHeader } from "../components/AppHeader";
import { useAuth } from "../providers/AuthProvider";
import { useEffect } from "react";
import { authUrl } from "../services/routes/urlBuilder";
import { BACKGROUND_GRADIENT } from "../services/theme/colors";

const headerHeight = "50px";

export const Root: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!pathname.includes("events") && pathname !== authUrl()) {
      if (!user) {
        navigate(authUrl(pathname));
      }
    }
  }, [navigate, pathname, user]);

  return (
    <Box height="100vh">
      <Box background={BACKGROUND_GRADIENT} height="auto" minHeight="100vh">
        <Box height={headerHeight} paddingX={5}>
          <AppHeader />
        </Box>
        <Box padding={1}>
          <Container maxW="container.sm">
            <Outlet />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
