import { useNavigate, useParams } from "react-router-dom";
import { createWaiver } from "../../services/api/waivers";
import { Waiver } from "../../types/waiver";
import { WaiverForm } from "./WaiverForm";
import { organizationShowUrl } from "../../services/routes/urlBuilder";

export const NewWaiver: React.FC = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (waiver: Waiver) => {
    if (!organizationId) return;

    await createWaiver(organizationId, waiver);

    navigate(organizationShowUrl(organizationId));
  };

  return <WaiverForm onSubmit={handleSubmit} />;
};
