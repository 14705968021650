export const TITLE_XL = {
  fontSize: "28px",
  lineHeight: "32px",
  fontWeight: 700,
};
export const TITLE_LG = {
  fontSize: "24px",
  lineHeight: "28px",
  fontWeight: 700,
};
export const TITLE_MD = {
  fontSize: "20px",
  lineHeight: "26px",
  fontWeight: 700,
};
export const TITLE_SM = {
  fontSize: "17px",
  lineHeight: "24px",
  fontWeight: 700,
};
export const TEXT_MAIN = {
  fontSize: "17px",
  lineHeight: "24px",
  fontWeight: 500,
};
export const TEXT_SM = {
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: 500,
};
