import { Outlet, useParams } from "react-router-dom";
import { CurrentEventUserProvider } from "../../../providers/CurrentEventUserProvider";

export const EventParentPage: React.FC = () => {
  const { eventId } = useParams();

  if (!eventId) return null;

  return (
    <CurrentEventUserProvider eventId={eventId}>
      <Outlet />
    </CurrentEventUserProvider>
  );
};
