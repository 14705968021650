import { useNavigate } from "react-router-dom";
import { Organization } from "../../types/organization";
import { OrganizationForm } from "./components/OrganizationForm";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";
import * as api from "../../services/api";
import { organizationShowUrl } from "../../services/routes/urlBuilder";

export const EditOrganization: React.FC = () => {
  const navigate = useNavigate();
  const { organization, refetch } = useCurrentOrganization();

  const handleSubmit = async (data: Organization) => {
    await api.organizations.updateOrganization(data);
    navigate(organizationShowUrl(data.id));
    await refetch();
  };

  return (
    <>
      <OrganizationForm onSubmit={handleSubmit} values={organization} />
    </>
  );
};
