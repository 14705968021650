import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./providers/AuthProvider";
import { FirebaseAppProvider } from "./providers/FirebaseAppProvider";
import { StripeProvider } from "./providers/StripeProvider";
import { vianikoTheme } from "./services/theme";
import { CurrentUserProvider } from "./providers/CurrentUserProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
  throw new Error("REACT_APP_STRIPE_PUB_KEY is required");
}

root.render(
  <React.StrictMode>
    <ChakraProvider theme={vianikoTheme}>
      <FirebaseAppProvider>
        <AuthProvider>
          <CurrentUserProvider>
            <StripeProvider>
              <App />
            </StripeProvider>
          </CurrentUserProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
