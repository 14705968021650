import { ButtonLink } from "../../../components/ButtonLink";
import { waiverNewUrl } from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { useWaivers } from "../../../hooks/useWaivers";
import { WaiverItem } from "./WaiverItem";

interface WaiversListProps {
  organizationId: string;
}

export const WaiversList: React.FC<WaiversListProps> = ({ organizationId }) => {
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const { waivers, refetch } = useWaivers(organizationId);

  const handleDelete = async () => {
    await refetch();
  };

  return (
    <Panel>
      {currentOrganizationUser?.is_owner && (
        <ButtonLink
          to={waiverNewUrl(organizationId)}
          buttonProps={{
            size: "lg",
            variant: "outline",
            width: "100%",
            marginBottom: 4,
          }}
        >
          Add Waiver
        </ButtonLink>
      )}
      {waivers?.map((waiver) => (
        <WaiverItem key={waiver.id} waiver={waiver} onDelete={handleDelete} />
      ))}
    </Panel>
  );
};
