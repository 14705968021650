import { HStack, LinkBox, LinkOverlay, VStack } from "@chakra-ui/react";
import { SocialIcon } from "./Icon";

interface SocialButtonsProps {
  instagramHandle: string;
  twitterHandle: string;
  linkedinUrl: string;
}

export const SocialButtons: React.FC<SocialButtonsProps> = ({
  instagramHandle,
  twitterHandle,
  linkedinUrl,
}) => {
  return (
    <VStack gap={4}>
      {instagramHandle && (
        <LinkBox width="100%">
          <HStack>
            <SocialIcon type="instagram" />
            <LinkOverlay
              href={`https://instagram.com/${instagramHandle}`}
              target="blank"
            >
              @{instagramHandle}
            </LinkOverlay>
          </HStack>
        </LinkBox>
      )}
      {twitterHandle && (
        <LinkBox width="100%">
          <HStack>
            <SocialIcon type="twitter" />
            <LinkOverlay
              href={`https://twitter.com/${twitterHandle}`}
              target="blank"
            >
              @{twitterHandle}
            </LinkOverlay>
          </HStack>
        </LinkBox>
      )}
      {linkedinUrl && (
        <LinkBox width="100%">
          <HStack>
            <LinkOverlay href={`https://${linkedinUrl}`} target="blank">
              {linkedinUrl}
            </LinkOverlay>
            <SocialIcon type="linkedin" />
          </HStack>
        </LinkBox>
      )}
    </VStack>
  );
};
