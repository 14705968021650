import { Elements } from "@stripe/react-stripe-js";
import {
  LoaderFunction,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as api from "../../services/api";
import { VianikoEvent } from "../../types/events";
import { PaymentForm } from "../events/components/PaymentForm";
import { Heading, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../services/payments";
import { useEffect, useState } from "react";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { eventShowUrl } from "../../services/routes/urlBuilder";

interface LoaderData {
  event: VianikoEvent;
}

export const loader: LoaderFunction = async ({
  params: { eventId },
}): Promise<LoaderData> => {
  if (!eventId) throw new Error("Event ID is required");

  const { event } = await api.events.fetchEvent(eventId);

  return { event };
};

export const EventPaymentPage: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { event } = useLoaderData() as LoaderData;
  const [paymentToken, setPaymentToken] = useState<string>();
  const [amountInCents, setAmountInCents] = useState<number>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { stripe } = useStripeAccount(event.organization_id);

  const amountParam = searchParams.get("amount") || "0";
  const numTicketsParam = searchParams.get("numTickets") || "1";

  useEffect(() => {
    async function init() {
      const amount = parseInt(amountParam);
      const numTickets = parseInt(numTicketsParam);

      if (currentUser) {
        const { paymentToken, amountInCents } =
          await api.payments.createPaymentIntent(event.id, {
            amountInCents: amount,
            numTickets,
          });

        setPaymentToken(paymentToken);
        setAmountInCents(amountInCents);
      }
    }

    init();
  }, [currentUser, amountParam, numTicketsParam, event.id, navigate, stripe]);

  if (!paymentToken || !amountInCents) {
    return null;
  }

  return (
    <>
      <Heading size="md" marginY={2}>
        Event Payment Page: {event.name}
      </Heading>
      <Text marginY={4}>Price: {priceInDollars(amountInCents)}</Text>
      <Elements stripe={stripe} options={{ clientSecret: paymentToken }}>
        <PaymentForm
          returnUrl={`${process.env.REACT_APP_BASE_URL}${eventShowUrl(
            event.id,
            { invite: true, welcome: true }
          )}`}
        />
      </Elements>
    </>
  );
};
