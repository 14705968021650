import { useEffect, useState } from "react";
import { VianikoUser } from "../types/users";
import { getPublicUser } from "../services/api/users";

export const useInstructor = (
  instructorId: string
): VianikoUser | undefined => {
  const [instructor, setInstructor] = useState<VianikoUser>();

  useEffect(() => {
    const fetchInstructor = async () => {
      if (instructorId) {
        const result = await getPublicUser(instructorId);
        setInstructor(result);
      }
    };

    fetchInstructor();
  }, [instructorId]);

  return instructor;
};
