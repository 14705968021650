import { useCallback, useEffect, useState } from "react";
import { getAccount } from "../services/api/payments";
import { initializeStripeAccount } from "../services/payments";
import { Stripe } from "@stripe/stripe-js";

interface UseStripeAccount {
  stripeAccountId: string | undefined;
  stripe: Stripe | null;
  refetch: () => void;
  loading: boolean;
}

export const useStripeAccount = (
  organizationId: string | undefined
): UseStripeAccount => {
  const [stripeAccountId, setStripeAccountId] = useState<string>();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchStripeAccountId = useCallback(async () => {
    if (!organizationId) return;

    setLoading(true);
    const { stripe_account_id } = await getAccount(organizationId);
    setLoading(false);

    if (stripe_account_id) {
      setStripeAccountId(stripe_account_id);
      const stripe = await initializeStripeAccount(stripe_account_id);
      setStripe(stripe);
    }
  }, [organizationId]);

  useEffect(() => {
    fetchStripeAccountId();
  }, [fetchStripeAccountId]);

  const refetch = useCallback(() => {
    fetchStripeAccountId();
  }, [fetchStripeAccountId]);

  return { stripeAccountId, stripe, refetch, loading };
};
