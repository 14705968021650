import React from "react";
import { CurrentOrganizationUserProvider } from "../../providers/CurrentOrganizationUserProvider";
import { Outlet, useParams } from "react-router-dom";
import { OrganizationProvider } from "../../providers/CurrentOrganizationProvider";

export const OrganizationParentPage: React.FC = () => {
  const { organizationId } = useParams();

  if (!organizationId) return null;

  return (
    <OrganizationProvider organizationId={organizationId}>
      <CurrentOrganizationUserProvider organizationId={organizationId}>
        <Outlet />
      </CurrentOrganizationUserProvider>
    </OrganizationProvider>
  );
};
