import { Box, StyleProps } from "@chakra-ui/react";

interface PanelProps extends StyleProps {
  children: React.ReactNode;
}

export const Panel: React.FC<PanelProps> = ({ children, ...styleProps }) => {
  return (
    <Box
      bg="red"
      borderRadius="24px"
      padding={5}
      marginY={1}
      background="rgba(255, 255, 255, 0.4)"
      {...styleProps}
    >
      {children}
    </Box>
  );
};
