import { Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { ButtonLink } from "../components/ButtonLink";
import { OrganizationListThemeCard } from "./organizations/components/OrganizationListThemeCard";
import { useCurrentUserOrganizations } from "../hooks/useCurrentUserOrganizations";
import { Panel } from "../components/Panel";
import { userShowUrl } from "../services/routes/urlBuilder";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { useUserEventsCount } from "../hooks/useUserEventsCount";
import { useFriendships } from "../hooks/useFriendships";
import { useUserEventsHostedCount } from "../hooks/useUserEventsHostedCount";

export const Home: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { organizations } = useCurrentUserOrganizations();
  const { count: eventsCount } = useUserEventsCount(currentUser?.id);
  const { count: eventsHostedCount } = useUserEventsHostedCount(
    currentUser?.id
  );
  const { friends } = useFriendships(currentUser?.id);

  if (!currentUser || !organizations) return null;

  return (
    <>
      <VStack spacing={0} align="left">
        <Panel>
          <HStack
            gap={4}
            textAlign="center"
            marginTop={2}
            marginBottom={6}
            marginX={2}
          >
            <Flex width="33.3%" justify="center" direction="column" gap={1}>
              <Heading size="lg">{eventsCount}</Heading>
              <Text size="md">events attended</Text>
            </Flex>
            <Flex width="33.3%" justify="center" direction="column" gap={1}>
              <Heading size="lg">{friends?.length || 0}</Heading>
              <Text size="md">friends made</Text>
            </Flex>
            <Flex width="33.3%" justify="center" direction="column" gap={1}>
              <Heading size="lg">{eventsHostedCount}</Heading>
              <Text size="md">events hosted </Text>
            </Flex>
          </HStack>

          <ButtonLink
            to={userShowUrl(currentUser.id, {
              tab: "events",
              eventsDirection: "future",
            })}
            buttonProps={{ variant: "outline", size: "lg", width: "100%" }}
          >
            My events
          </ButtonLink>
        </Panel>

        <ButtonLink
          to="/organizations/new"
          buttonProps={{
            variant: "primary",
            size: "lg",
            width: "100%",
            marginY: 1,
          }}
          width="100%"
        >
          Start a feather
        </ButtonLink>

        {organizations.length > 0 && (
          <>
            <Flex align="center" marginY={3} marginX={5}>
              <Heading as="h1" size="sm">
                Your feathers
              </Heading>
            </Flex>
            <VStack width="100%" gap={1}>
              {organizations.map((org) => (
                <OrganizationListThemeCard key={org.id} organization={org} />
              ))}
            </VStack>
          </>
        )}
      </VStack>
    </>
  );
};
