import { useCallback, useEffect, useState } from "react";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { getFriendshipWithUser } from "../services/api/users";
import { Friendship } from "../types/friendships";

interface UseFriendshipResponse {
  friendship: Friendship | null;
  refetch: () => void;
}

export const useFriendship = (
  userId: string | undefined
): UseFriendshipResponse => {
  const [friendship, setFriendship] = useState<Friendship | null>(null);
  const { currentUser } = useCurrentUser();

  const fetchFriendship = useCallback(async () => {
    if (!currentUser || !userId) return;
    const result = await getFriendshipWithUser(userId);
    setFriendship(result);
  }, [currentUser, userId]);

  useEffect(() => {
    fetchFriendship();
  }, [fetchFriendship]);

  return { friendship, refetch: fetchFriendship };
};
