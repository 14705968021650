import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Icon,
} from "@chakra-ui/react";
import { Waiver } from "../../types/waiver";
import { createWaiverSignature, fetchWaiver } from "../../services/api/waivers";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { organizationShowUrl } from "../../services/routes/urlBuilder";

export const ShowWaiver: React.FC = () => {
  const [waiver, setWaiver] = useState<Waiver>();
  const [searchParams] = useSearchParams();
  const { organizationId, waiverId } = useParams();
  const redirectTo = searchParams.get("redirectTo");
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      if (!organizationId || !waiverId) return;
      const result = await fetchWaiver(organizationId, waiverId);
      setWaiver(result);
    };

    fetch();
  }, [organizationId, waiverId]);

  const handleRedirect = () => {
    if (redirectTo) {
      navigate(redirectTo);
    } else if (organizationId) {
      navigate(organizationShowUrl(organizationId));
    }
  };

  const handleClose = () => {
    if (organizationId) {
      navigate(organizationShowUrl(organizationId));
    }
  };

  const handleSign = async () => {
    if (!organizationId || !waiverId) return;
    await createWaiverSignature(organizationId, waiverId);
    handleRedirect();
  };

  if (!waiver) return null;

  return (
    <>
      <Modal isOpen={true} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h2" size="xl">
              {waiver.name}
            </Heading>
          </ModalHeader>
          <ModalCloseButton margin={2}>
            <Icon as={AiOutlineCloseCircle} fontSize={36} />
          </ModalCloseButton>
          <ModalBody>
            {waiver?.content &&
              waiver?.content
                .split("\n")
                .map((line, index) => <Text key={index}>{line}</Text>)}
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              width="100%"
              size="lg"
              marginBottom={12}
              onClick={handleSign}
            >
              Sign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
