import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";

interface StyledInputProps {
  name: string;
  label?: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  type?: string;
  options?: RegisterOptions;
  inputProps?: InputProps;
  leftElement?: ReactNode;
}

export const StyledInput: React.FC<StyledInputProps> = ({
  name,
  label,
  register,
  error,
  type,
  options,
  leftElement,
  inputProps,
}) => {
  const isRequired = options?.required;

  return (
    <FormControl isInvalid={!!error} width="100%" marginTop={0}>
      {(label || !isRequired) && type !== "hidden" && (
        <HStack align="center" marginBottom={2}>
          {label && (
            <FormLabel htmlFor={name} marginBottom={0}>
              <Text size="md">{label}</Text>
            </FormLabel>
          )}
          <Spacer />
          {!isRequired && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              Optional
            </Text>
          )}
        </HStack>
      )}
      <InputGroup marginTop={0}>
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        <Input
          {...inputDefaultThemeProps}
          {...inputProps}
          type={type || "text"}
          {...register(name, options)}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
