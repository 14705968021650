import { Heading } from "@chakra-ui/react";
import * as api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { RecurringEventForm } from "./Form";
import { RecurringEvent } from "../../types/recurring_events";
import { organizationShowUrl } from "../../services/routes/urlBuilder";

export const NewRecurringEvent: React.FC = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (data: RecurringEvent) => {
    if (!organizationId) return;

    await api.recurringEvents.createRecurringEvent(organizationId, data);
    navigate(organizationShowUrl(organizationId));
  };

  return (
    <>
      <Heading as="h1" size="lg">
        Create Recurring Event
      </Heading>
      <RecurringEventForm onSubmit={handleSubmit} />
    </>
  );
};
