import { DatetimeDirection } from "../../types/datetime";
import { VianikoUser } from "../../types/users";
import axiosInstance from "./axiosInstance";

export const getUser = async (id: string) => {
  const result = await axiosInstance.get(`/users/${id}`);
  return result.data;
};

export const getPublicUser = async (id: string) => {
  const result = await axiosInstance.get(`/public/users/${id}`);
  return result.data;
};

export const getCurrentUser = async () => {
  const result = await axiosInstance.get("/users/current");
  return result.data;
};

export const updateUser = async (data: VianikoUser) => {
  const result = await axiosInstance.put(`/users/${data.id}`, data);
  return result.data;
};

export const getFriendshipWithUser = async (id: string) => {
  const result = await axiosInstance.get(`/users/${id}/friendships/my`);
  return result.data;
};

export const fetchUserOrganizations = async (userId: string) => {
  const result = await axiosInstance.get(`/users/${userId}/organizations`);
  return result.data;
};

export const fetchUserEvents = async (
  userId: string,
  direction: DatetimeDirection
) => {
  const result = await axiosInstance.get(
    `/users/${userId}/events?direction=${direction}`
  );
  return result.data;
};

export const fetchUserEventsCount = async (userId: string) => {
  const result = await axiosInstance.get(`/users/${userId}/events/count`);
  return result.data;
};

export const fetchUserEventsHostedCount = async (userId: string) => {
  const result = await axiosInstance.get(
    `/users/${userId}/events/hosted-count`
  );
  return result.data;
};
