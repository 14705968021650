import { useState } from "react";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { refundTickets } from "../../../services/api/organizations";
import { BiDotsHorizontalRounded } from "react-icons/bi";

interface AdminMoreActionsMenuProps {
  eventUserId: string;
  organizationId: string;
  onRefundTickets: () => void;
}

export const AdminMoreActionsMenu: React.FC<AdminMoreActionsMenuProps> = ({
  eventUserId,
  organizationId,
  onRefundTickets,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const handleRefundTickets = async () => {
    await refundTickets(organizationId, eventUserId);
    setIsRefundModalOpen(false);
    onRefundTickets();
  };

  return (
    <Box position="relative">
      <IconButton
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        variant="ghost"
        aria-label="More"
        icon={<Icon as={BiDotsHorizontalRounded} />}
        margin={0}
      />
      <Menu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        closeOnBlur
      >
        <MenuList>
          <MenuItem onClick={() => setIsRefundModalOpen(true)}>
            Refund Tickets
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to refund this user's tickets? This action
              cannot be undone.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsRefundModalOpen(false)}>
              Close
            </Button>
            <Button onClick={handleRefundTickets} colorScheme="red">
              Refund Tickets
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
