import axiosInstance from "./axiosInstance";

export const fetchFriendshipRequests = async () => {
  const response = await axiosInstance.get("/friendship_requests");
  return response.data;
};

export const createFriendshipRequest = async (userId: string) => {
  const response = await axiosInstance.post(`/friendship_requests`, {
    user_id: userId,
  });
  return response.data;
};

export const acceptFriendshipRequest = async (friendshipRequestId: string) => {
  const response = await axiosInstance.put(
    `/friendship_requests/${friendshipRequestId}/accept`
  );
  return response.data;
};

export const ignoreFriendshipRequest = async (friendshipRequestId: string) => {
  const response = await axiosInstance.put(
    `/friendship_requests/${friendshipRequestId}/ignore`
  );
  return response.data;
};
