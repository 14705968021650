import linkifyStr from "linkify-string";

export const formatDateForDatetimeLocal = (isoDate: string) => {
  const isoStringWithoutTZ = isoDate.slice(0, -8);

  return isoStringWithoutTZ;
};

export const getHtmlFromString = (string: string): string => {
  return linkifyStr(string).replace(/\n/g, "<br />");
};
