import {
  Button,
  Flex,
  Heading,
  HStack,
  LinkBox,
  LinkOverlay,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useCallback, useEffect, useState } from "react";
import { VianikoEvent } from "../../../types/events";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { Link as RouterLink } from "react-router-dom";
import { eventShowUrl } from "../../../services/routes/urlBuilder";
import {
  formatDatePastEvent,
  formatDateUpcomingEvent,
} from "../../../services/datetime";
import { fetchOrganizationEvents } from "../../../services/api/organizations";
import { DatetimeDirection } from "../../../types/datetime";
import { FilterButtonMenu } from "../../../components/FilterButtonMenu";

interface OrganizationCalendarProps {
  linkExternal?: boolean;
}

export const OrganizationCalendar: React.FC<OrganizationCalendarProps> = ({
  linkExternal,
}) => {
  const { organization } = useCurrentOrganization();
  const [events, setEvents] = useState<VianikoEvent[]>([]);
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState<DatetimeDirection>("future");

  const fetchEvents = useCallback(
    async (direction: DatetimeDirection, afterTimestamp: string) => {
      if (!organization) return;

      setLoading(true);

      const events = await fetchOrganizationEvents(
        organization.id,
        direction,
        afterTimestamp
      );
      setLoading(false);

      return events;
    },
    [organization]
  );

  const fetchAndReplaceEvents = useCallback(
    async (direction: DatetimeDirection, afterTimestamp: string) => {
      const events = await fetchEvents(direction, afterTimestamp);
      setEvents(events);
    },
    [fetchEvents]
  );

  useEffect(() => {
    if (!organization) return;
    fetchAndReplaceEvents(direction, new Date().toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const handleLoadMore = async () => {
    const lastEvent = events[events.length - 1];
    if (!lastEvent) return;

    const nextEvents = await fetchEvents(direction, lastEvent.end_at);
    if (nextEvents) {
      setEvents((prevEvents) => [...prevEvents, ...nextEvents]);
    }
  };

  const handleSelectFilter = async (filter: DatetimeDirection) => {
    await fetchAndReplaceEvents(filter, new Date().toISOString());
    setDirection(filter);
  };

  if (!organization) return null;

  return (
    <Panel>
      <HStack>
        <Text size="lg" marginBottom={4}>
          {direction === "future" ? "Upcoming" : "Past"}
        </Text>
        <Spacer />
        <FilterButtonMenu<DatetimeDirection>
          options={[
            { label: "Upcoming", value: "future" },
            { label: "Past", value: "past" },
          ]}
          onSelect={handleSelectFilter}
        />
      </HStack>

      {!loading && events.length === 0 && (
        <Text>No {direction === "future" ? "upcoming" : "past"} events</Text>
      )}

      <Flex flexWrap="wrap" gap={2}>
        {events.map((event) => (
          <LinkBox key={event.id} width="calc(50% - 8px)">
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {direction === "future"
                ? formatDateUpcomingEvent(new Date(event.start_at))
                : formatDatePastEvent(new Date(event.start_at))}
            </Text>
            <LinkOverlay
              as={RouterLink}
              to={eventShowUrl(event.id)}
              isExternal={linkExternal}
            >
              <Heading as="h4" size="sm" noOfLines={4}>
                {event.name}
              </Heading>
            </LinkOverlay>
          </LinkBox>
        ))}
      </Flex>

      {loading && (
        <VStack marginTop={4}>
          <Spinner />
        </VStack>
      )}

      <Button
        size="lg"
        variant="primary"
        width="100%"
        marginTop={4}
        onClick={handleLoadMore}
      >
        Load more
      </Button>
    </Panel>
  );
};
