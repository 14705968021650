import {
  Box,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Waiver } from "../../../types/waiver";
import { BiBookHeart } from "react-icons/bi";
import { waiverShowUrl } from "../../../services/routes/urlBuilder";
import { ManageWaiverMenu } from "./ManageWaiverMenu";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { ButtonLink } from "../../../components/ButtonLink";
import { useEffect, useState } from "react";
import { WaiverSignature } from "../../../types/waiver_signature";
import { fetchWaiverSignature } from "../../../services/api/waivers";

interface WaiverItemProps {
  waiver: Waiver;
  onDelete: () => void;
}

export const WaiverItem: React.FC<WaiverItemProps> = ({ waiver, onDelete }) => {
  const { currentOrganizationUser } = useCurrentOrganizationUser();
  const [waiverSignature, setWaiverSignature] = useState<WaiverSignature>();

  useEffect(() => {
    const fetch = async () => {
      if (!waiver.id) return;
      const result = await fetchWaiverSignature(
        waiver.organization_id,
        waiver.id
      );
      setWaiverSignature(result);
    };

    fetch();
  }, [waiver]);

  return (
    <LinkBox>
      <HStack>
        <Icon as={BiBookHeart} />
        <Box>
          <LinkOverlay
            as={RouterLink}
            to={waiverShowUrl(waiver.organization_id, waiver.id)}
          >
            <Text size="md">{waiver.name}</Text>
          </LinkOverlay>
          <Text size="sm" variant="secondary" marginTop={1}>
            {waiver.created_at === waiver.updated_at
              ? `Uploaded ${waiver.created_at}`
              : `Updated ${waiver.updated_at}`}
          </Text>
        </Box>
        <Spacer />
        {currentOrganizationUser?.is_owner ? (
          <ManageWaiverMenu
            organizationId={waiver.organization_id}
            waiver={waiver}
            onDelete={onDelete}
          />
        ) : (
          <ButtonLink
            to={waiverShowUrl(waiver.organization_id, waiver.id)}
            buttonProps={{
              size: "sm",
              variant: "primary",
              isDisabled: !!waiverSignature,
            }}
          >
            {waiverSignature ? "Signed" : "Sign"}
          </ButtonLink>
        )}
      </HStack>
    </LinkBox>
  );
};
