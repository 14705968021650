import { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  createEventMessage,
  fetchEventMessages,
} from "../../../services/api/events";
import { Message } from "../../../types/messages";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { Panel } from "../../../components/Panel";
import { useForm } from "react-hook-form";
import { StyledTextarea } from "../../../components/forms/StyledTextarea";

export const EventAdminMessages = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Message>({ mode: "onBlur" });

  const [messages, setMessages] = useState<Message[]>();
  const { eventId } = useParams();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    async function fetchMessages() {
      if (!eventId || !currentUser) return;

      const messages = await fetchEventMessages(eventId);
      setMessages(messages);
    }

    fetchMessages();
  }, [eventId, currentUser]);

  const onSubmit = async (data: Message) => {
    const { content } = data;
    if (!eventId || !content) return;

    await createEventMessage(eventId, content);

    const messages = await fetchEventMessages(eventId);
    setMessages(messages);
    setValue("content", "");
  };

  return (
    <Panel>
      <Heading as="h2" size="sm" marginBottom={4}>
        Send SMS to event attendees
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledTextarea
          name="content"
          register={register}
          options={{
            required: "This field is required",
            maxLength: {
              message: "Must be less than 500 characters",
              value: 500,
            },
          }}
          error={errors.content}
        />
        <Button
          type="submit"
          variant="primary"
          size="lg"
          width="100%"
          marginY={1}
        >
          Send
        </Button>
      </form>

      <Heading as="h2" size="sm" my={4}>
        SMS message history
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Message</Th>
            <Th>Sent at</Th>
          </Tr>
        </Thead>
        <Tbody>
          {messages?.map((message) => (
            <Tr key={message.id}>
              <Td>{message.content}</Td>
              <Td>{message.created_at}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Panel>
  );
};
