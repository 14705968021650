import { useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useEvent } from "../../hooks/useEvent";
import { fetchUnsignedWaivers } from "../../services/api/payments";
import {
  eventPaymentUrl,
  eventShowUrl,
  paymentsRedirectorUrl,
  userEditUrl,
  waiverShowUrl,
} from "../../services/routes/urlBuilder";
import { createEventUser } from "../../services/api/eventUsers";

export const EventPaymentRedirector: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { eventId } = useParams();
  const { event } = useEvent(eventId);
  const navigate = useNavigate();

  const amountParam = searchParams.get("amount") || "0";
  const numTickets = searchParams.get("numTickets") || "1";

  useEffect(() => {
    const init = async () => {
      if (!event || !currentUser) return;

      await createEventUser(event.id, currentUser.id);

      if (!currentUser.full_name) {
        navigate(userEditUrl(currentUser.id, paymentsRedirectorUrl(event.id)));
        return;
      }

      const { organization_id: organizationId } = event;
      if (organizationId) {
        const [firstWaiver] = await fetchUnsignedWaivers(organizationId);

        if (firstWaiver) {
          navigate(
            waiverShowUrl(
              organizationId,
              firstWaiver.id,
              paymentsRedirectorUrl(event.id)
            )
          );
          return;
        }
      }

      if (event.payment_type === "free") {
        navigate(eventShowUrl(event.id, { invite: true, welcome: true }));
        return;
      }

      navigate(eventPaymentUrl(event.id, { amount: amountParam, numTickets }));
    };

    init();
  }, [event, currentUser, navigate, amountParam, numTickets]);

  return <Outlet />;
};
