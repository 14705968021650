export const homeUrl = () => "/";

export const authUrl = (redirectTo?: string) => {
  const redirect = redirectTo ? `?redirect=${redirectTo}` : "";
  return `/auth${redirect}`;
};

interface UserShowUrlParams {
  tab: "events";
  eventsDirection: "future";
}
export const userShowUrl = (userId: string, params?: UserShowUrlParams) => {
  const { tab, eventsDirection } = params || {};
  const urlParams = params
    ? new URLSearchParams({
        tab: tab ? tab.toString() : "",
        eventsDirection: eventsDirection ? eventsDirection.toString() : "",
      })
    : "";

  return `/users/${userId}?${urlParams.toString()}`;
};

export const userEditUrl = (userId: string, redirectAfter?: string) =>
  `/users/${userId}/edit${
    redirectAfter ? `?redirectAfter=${redirectAfter}` : ""
  }`;

export const organizationShowUrl = (organizationId: string) =>
  `/organizations/${organizationId}`;
export const organizationEditUrl = (organizationId: string) =>
  `/organizations/${organizationId}/edit`;
export const organizationManagePaymentAccountUrl = (organizationId: string) =>
  `/organizations/${organizationId}/settings/payment-account`;

interface EventShowUrlParams {
  invite?: boolean;
  welcome?: boolean;
}
export const eventShowUrl = (eventId: string, params?: EventShowUrlParams) => {
  const { invite, welcome } = params || {};
  const urlParams = params
    ? new URLSearchParams({
        invite: invite ? invite.toString() : "",
        welcome: welcome ? welcome.toString() : "",
      })
    : "";

  return `/events/${eventId}?${urlParams.toString()}`;
};

export const eventEditUrl = (eventId: string) => `/events/${eventId}/edit`;
export const eventNewUrl = (organizationId?: string) =>
  `${organizationId ? `/organizations/${organizationId}` : ""}/events/new`;
interface EventPaymentUrlParams {
  amount: string;
  numTickets: string;
}
export const eventPaymentUrl = (
  eventId: string,
  params?: EventPaymentUrlParams
) =>
  `/events/${eventId}/payments/new${
    params ? `?amount=${params.amount}&numTickets=${params.numTickets}` : ""
  }`;

export const paymentsRedirectorUrl = (eventId: string) =>
  `/events/${eventId}/payments`;

export const recurringEventShowUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}`;
export const recurringEventEditUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}/edit`;
export const recurringEventNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/recurring_events/new`;

export const waiverNewUrl = (organizationId: string) =>
  `/organizations/${organizationId}/waivers/new`;
export const waiverEditUrl = (organizationId: string, waiverId: string) =>
  `/organizations/${organizationId}/waivers/${waiverId}/edit`;
export const waiverShowUrl = (
  organizationId: string,
  waiverId: string,
  redirectTo?: string
) =>
  `/organizations/${organizationId}/waivers/${waiverId}${
    redirectTo ? `?redirectTo=${encodeURIComponent(redirectTo)}` : ""
  }`;
