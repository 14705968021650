import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { inputDefaultThemeProps } from "../services/theme/overrides/input";
import { BiCopy } from "react-icons/bi";

interface CopyToClipboardButtonProps {
  text: string;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  text,
}) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    toast({
      description: "Link copied to clipboard",
      status: "success",
      duration: 2000,
    });
  };

  return (
    <InputGroup onClick={handleCopy}>
      <Input size="lg" value={text} isDisabled {...inputDefaultThemeProps} />
      <InputRightElement margin={1}>
        <Icon as={BiCopy}></Icon>
      </InputRightElement>
    </InputGroup>
  );
};
