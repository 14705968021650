import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    width: "24px",
    height: "24px",
    borderRadius: "8px",
    border: "2px solid black !important",
    backgroundColor: "white",
    _checked: {
      backgroundColor: "black",
    },
  },
});

const defaultProps = {
  colorScheme: "black",
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps,
});
