import { Organization } from "../../types/organization";
import axiosInstance from "./axiosInstance";

export const fetchOrganization = async (id: string) => {
  const result = await axiosInstance.get(`/public/organizations/${id}`);
  return result.data;
};

export const fetchOrganizationEvents = async (
  id: string,
  direction: "past" | "future",
  afterTimestamp: string
) => {
  const result = await axiosInstance.get(
    `/public/organizations/${id}/events?direction=${direction}&afterTimestamp=${encodeURIComponent(
      afterTimestamp
    )}`
  );
  return result.data;
};

export const fetchOrganizationUsers = async (id: string) => {
  const result = await axiosInstance.get(`/organizations/${id}/users`);
  return result.data;
};

export const fetchNextEvent = async (id: string) => {
  const result = await axiosInstance.get(`/organizations/${id}/next_event`);
  return result.data;
};

export const createOrganization = async (data: Organization) => {
  const result = await axiosInstance.post("/organizations", data);
  return result.data;
};

export const updateOrganization = async (data: Organization) => {
  const result = await axiosInstance.put(`/organizations/${data.id}`, data);
  return result.data;
};

export const deleteOrganization = async (id: string) => {
  await axiosInstance.delete(`/organizations/${id}`);
};

export const refundTickets = async (
  organizationId: string,
  eventUserId: string
) => {
  const response = await axiosInstance.post(
    `/organizations/${organizationId}/event_users/${eventUserId}/refund`
  );
  return response.data;
};

export const inviteUserToOrganization = async (
  organizationId: string,
  userId: string
) => {
  const result = await axiosInstance.post(
    `/organizations/${organizationId}/invite`,
    {
      user_id: userId,
    }
  );
  return result.data;
};
