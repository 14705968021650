import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ButtonLink } from "../../components/ButtonLink";
import * as api from "../../services/api";
import { RecurringEvent } from "../../types/recurring_events";
import { HStack, Link, Spacer, Table, Tbody, Td, Tr } from "@chakra-ui/react";

interface RecurringEventsListProps {
  organizationId: string;
}

export const RecurringEventsList: React.FC<RecurringEventsListProps> = ({
  organizationId,
}) => {
  const [recurringEvents, setRecurringEvents] = useState<RecurringEvent[]>();

  useEffect(() => {
    const fetchRecurringEvents = async () => {
      const recurringEvents = await api.recurringEvents.fetchRecurringEvents(
        organizationId
      );
      setRecurringEvents(recurringEvents);
    };

    fetchRecurringEvents();
  }, [organizationId]);

  return (
    <>
      <HStack>
        <Spacer />
        <ButtonLink
          to={`/organizations/${organizationId}/recurring_events/new`}
        >
          Create recurring event
        </ButtonLink>
      </HStack>
      <Table>
        <Tbody>
          {recurringEvents?.map((recurringEvent) => (
            <Tr key={recurringEvent.id}>
              <Td>
                <Link
                  as={RouterLink}
                  key={recurringEvent.id}
                  to={`/recurring_events/${recurringEvent.id}/edit`}
                >
                  {recurringEvent.name}
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};
