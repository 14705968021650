import axiosInstance from "./axiosInstance";
import { VianikoUser } from "../../types/users";
import { UseFriendshipFriend } from "../../hooks/useFriendships";

export const fetchOrganizationFriendships = async (
  organizationId: string
): Promise<VianikoUser[]> => {
  const { data } = await axiosInstance.get(
    `/organizations/${organizationId}/friendships`
  );
  return data;
};

export const fetchAllFriendships = async (
  userId: string
): Promise<UseFriendshipFriend[]> => {
  const { data } = await axiosInstance.get(`users/${userId}/friendships`);
  return data;
};

export const removeFriendship = async (
  userId: string,
  friendshipId: string
): Promise<void> => {
  const { data } = await axiosInstance.put(
    `users/${userId}/friendships/${friendshipId}`,
    {
      status: "inactive",
    }
  );
  return data;
};
