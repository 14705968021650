import { MenuItem, Link, Icon, Text, Portal } from "@chakra-ui/react";
import { BiPencil, BiTrashAlt } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { eventEditUrl } from "../../services/routes/urlBuilder";
import { useState } from "react";
import { deleteEvent } from "../../services/api/events";
import { DeleteConfirmationModal } from "../../components/DeleteConfirmationModal";

interface ManageEventMenuListItemsProps {
  eventId: string;
  onDelete: () => void;
}

export const ManageEventMenuListItems: React.FC<
  ManageEventMenuListItemsProps
> = ({ eventId, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    await deleteEvent(eventId);
    setIsOpen(false);
    await onDelete();
  };

  return (
    <>
      <Link as={NavLink} to={eventEditUrl(eventId)}>
        <MenuItem>
          <Icon as={BiPencil} marginRight={2} />
          <Text size="md">Edit Event</Text>
        </MenuItem>
      </Link>
      <Link onClick={() => setIsOpen(true)}>
        <MenuItem>
          <Icon as={BiTrashAlt} marginRight={2} />
          <Text size="md">Delete Event</Text>
        </MenuItem>
      </Link>

      <Portal>
        <DeleteConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onDelete={handleDelete}
        />
      </Portal>
    </>
  );
};
