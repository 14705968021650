import { useForm } from "react-hook-form";
import { VianikoEvent } from "../../../../types/events";
import { StyledInput } from "../../../../components/forms/StyledInput";
import { useParams } from "react-router-dom";
import { Button, VStack } from "@chakra-ui/react";
import { StyledDateTimeInput } from "../../../../components/forms/StyledDateTimeInput";
import { OrganizationUserSelectInput } from "../../../../components/OrganizationUserSelectInput";
import { PaymentConfigurationFormSection } from "./PaymentConfigurationFormSection";
import { Panel } from "../../../../components/Panel";
import { useOrganization } from "../../../../providers/CurrentOrganizationProvider";
import { StyledTextarea } from "../../../../components/forms/StyledTextarea";

interface EventFormProps {
  onSubmit: (data: VianikoEvent) => void;
  submitText?: string;
  defaultValues?: VianikoEvent;
  formAddon?: React.ReactNode;
  timeOnly?: boolean;
}

export const EventForm: React.FC<EventFormProps> = ({
  onSubmit: onSubmitProp,
  submitText,
  defaultValues,
  formAddon,
  timeOnly = false,
}) => {
  const { organizationId: organizationIdParam } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<VianikoEvent>({ defaultValues, mode: "onBlur" });
  const organizationId = organizationIdParam ?? defaultValues?.organization_id;
  const { organization } = useOrganization(organizationId);
  const onSubmit = (data: VianikoEvent) => {
    onSubmitProp(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formAddon}

      {organizationId && (
        <StyledInput
          type="hidden"
          name="organization_id"
          register={register}
          inputProps={{ value: organizationId }}
        />
      )}
      <StyledInput
        type="hidden"
        name="max_num_tickets"
        register={register}
        inputProps={{ value: 1 }}
      />
      <Panel>
        <VStack gap={4}>
          <StyledInput
            name="name"
            label="Event name"
            type="text"
            register={register}
            options={{
              required: "This field is required",
              maxLength: {
                message: "Must be less than 100 characters",
                value: 100,
              },
            }}
            error={errors.name}
          />
          <StyledTextarea
            name="description"
            label="Description"
            register={register}
            options={{
              required: "This field is required",
              maxLength: {
                message: "Must be less than 20,000 characters",
                value: 20000,
              },
            }}
            error={errors.description}
          />
          <StyledInput
            name="location"
            label="Location"
            type="text"
            register={register}
            options={{ required: "This field is required" }}
            error={errors.location}
          />
        </VStack>
      </Panel>

      <Panel>
        <VStack gap={4} width="100%">
          <StyledDateTimeInput
            timeOnly={timeOnly}
            name="start_at"
            label="Start time"
            control={control}
            defaultValue={defaultValues?.start_at}
            error={errors.start_at}
            rules={{ required: "This field is required" }}
          />
          <StyledDateTimeInput
            timeOnly={timeOnly}
            name="end_at"
            label="End time"
            control={control}
            defaultValue={defaultValues?.end_at}
            error={errors.end_at}
            rules={{ required: "This field is required" }}
          />
        </VStack>
      </Panel>

      {organizationId && (
        <Panel>
          <VStack gap={4} width="100%">
            <OrganizationUserSelectInput
              name="instructor_id"
              organizationId={organizationId}
              register={register}
              error={errors.instructor_id}
              clearErrors={clearErrors}
              setValue={setValue}
              defaultValue={defaultValues?.instructor_id}
            />
            <StyledInput
              name="capacity"
              label="Capacity"
              type="number"
              register={register}
              options={{
                required: "This field is required",
                min: {
                  message: "Must be at least 1",
                  value: 1,
                },
              }}
              error={errors.capacity}
            />
          </VStack>
        </Panel>
      )}

      <Panel>
        <PaymentConfigurationFormSection
          control={control}
          errors={errors}
          organization={organization}
        />
      </Panel>
      <Button
        type="submit"
        variant="primary"
        width="100%"
        marginTop={6}
        marginBottom={12}
      >
        {submitText || "Save"}
      </Button>
    </form>
  );
};
