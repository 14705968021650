import {
  Flex,
  Text,
  Spacer,
  Button,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { UserAvatar } from "../../../components/UserAvatar";
import { useFriendshipRequests } from "../hooks/useFriendshipRequests";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { ExtendedUser } from "../hooks/useAttendees";
import { useAllCurrentUserFriendships } from "../../../hooks/useAllCurrentUserFriendships";
import { Panel } from "../../../components/Panel";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { AdminMoreActionsMenu } from "./AdminMoreActionsMenu";
import { VianikoEvent } from "../../../types/events";
import { userShowUrl } from "../../../services/routes/urlBuilder";

interface EventAttendeesProps {
  event: VianikoEvent;
  attendees: ExtendedUser[] | undefined;
  onRefundTickets?: () => void;
}

export const EventAttendees: React.FC<EventAttendeesProps> = ({
  event,
  attendees,
  onRefundTickets,
}) => {
  const { friendshipRequestOutgoingByUserId, create } = useFriendshipRequests();
  const { currentUser } = useCurrentUser();
  const { currentEventUser } = useCurrentEventUser();
  const { currentOrganizationUser } = useCurrentOrganizationUser();
  const { friendsByUserId } = useAllCurrentUserFriendships();

  const handleAddFriend = (userId: string) => async () => {
    await create(userId);
  };

  const handleRefundTickets = async () => {
    if (onRefundTickets) {
      await onRefundTickets();
    }
  };

  const isAdmin =
    currentEventUser?.is_owner || currentOrganizationUser?.is_owner;

  if (!attendees) return null;

  return (
    <Panel>
      <Flex gap={4} direction="column">
        {attendees?.map((attendee, index) => (
          <LinkBox key={attendee.id}>
            <Flex gap={2} align="center">
              <UserAvatar size="md" user={attendee} />
              <LinkOverlay as={RouterLink} to={userShowUrl(attendee.id)}>
                <Text flexGrow={4} whiteSpace="nowrap" noOfLines={1}>
                  {attendee.full_name}
                </Text>
              </LinkOverlay>
              <Spacer />

              {friendsByUserId[attendee.id] ? (
                <Button isDisabled>Friend</Button>
              ) : friendshipRequestOutgoingByUserId?.[attendee.id] ? (
                <Button isDisabled>Awaiting</Button>
              ) : attendee.id !== currentUser?.id ? (
                <Button
                  variant="primary"
                  onClick={handleAddFriend(attendee.id)}
                >
                  Add friend
                </Button>
              ) : null}

              {isAdmin && currentEventUser && event.organization_id && (
                <AdminMoreActionsMenu
                  eventUserId={attendee.event_user_id}
                  organizationId={event.organization_id}
                  onRefundTickets={handleRefundTickets}
                />
              )}
            </Flex>
          </LinkBox>
        ))}
      </Flex>
    </Panel>
  );
};
