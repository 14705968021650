import React, { createContext, useState, useEffect } from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";

interface StripeContextProps {
  stripe: Stripe | null;
}

const StripeContext = createContext<StripeContextProps>({
  stripe: null,
});

interface StripeProviderProps {
  children: React.ReactNode;
}

export const StripeProvider: React.FC<StripeProviderProps> = ({ children }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    const initializeStripe = async () => {
      if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
        throw new Error("REACT_APP_STRIPE_PUB_KEY is required");
      }

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
      setStripe(stripe);
    };

    initializeStripe();
  }, []);

  return (
    <StripeContext.Provider value={{ stripe }}>
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeInstance = () => {
  const context = React.useContext(StripeContext);
  if (!context) {
    throw new Error("useStripe must be used within a StripeProvider");
  }
  return { stripe: context.stripe };
};

export default StripeContext;
