import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { StyledInput } from "../../../components/forms/StyledInput";
import { StyledTextarea } from "../../../components/forms/StyledTextarea";
import { useForm } from "react-hook-form";
import { Organization } from "../../../types/organization";
import { Panel } from "../../../components/Panel";
import { OrganizationThemeCarouselInput } from "../../../components/forms/OrganizationThemeCarouselInput";
import { PhotoUploadInput } from "../../../components/forms/PhotoUploadInput";

interface OrganizationFormProps {
  onSubmit: (data: Organization) => void;
  values?: Organization;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  onSubmit,
  values,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Organization>({ values, mode: "onBlur" });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel>
        <Heading size="xl" marginBottom={10}>
          {values ? "Edit feather" : "Create feather"}
        </Heading>
        <VStack gap={4}>
          <StyledInput
            name="name"
            label="Name"
            type="text"
            register={register}
            options={{ required: "This field is required" }}
            error={errors.name}
          />
          <StyledInput
            name="type"
            label="Type"
            type="text"
            register={register}
            options={{
              required: "This field is required",
              maxLength: { value: 255, message: "Max length is 255" },
            }}
            error={errors.type}
          />
          <StyledTextarea
            name="description"
            label="Description"
            register={register}
            options={{
              maxLength: {
                message: "Must be less than 20,000 characters",
                value: 20000,
              },
            }}
            error={errors.description}
          />
        </VStack>
      </Panel>

      <Panel>
        <OrganizationThemeCarouselInput control={control} />
      </Panel>

      <Panel>
        <Text size="md">Photos</Text>
        <PhotoUploadInput
          name="photo_ids"
          control={control}
          type="multiple"
          previewProps={{
            height: "124px",
            width: "100%",
          }}
        />
      </Panel>

      <Button
        type="submit"
        variant="primary"
        size="lg"
        width="100%"
        marginTop={6}
        marginBottom={12}
      >
        {values ? "Save feather" : "Create feather"}
      </Button>
    </form>
  );
};
