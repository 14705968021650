import { useEffect, useState } from "react";
import { VianikoEvent } from "../types/events";
import * as api from "../services/api";

export const useVianikoEvent = (eventId: string | undefined) => {
  const [vianikoEvent, setVianikoEvent] = useState<VianikoEvent | undefined>();

  useEffect(() => {
    const fetchEvent = async () => {
      if (!eventId) return;

      const { event } = await api.events.fetchEvent(eventId);
      setVianikoEvent(event);
    };

    fetchEvent();
  }, [eventId]);

  return vianikoEvent;
};
