import {
  Box,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
} from "@chakra-ui/react";
import { EventForm } from "../events/components/forms/EventForm";
import { useEffect, useState } from "react";
import { RRule } from "rrule";
import { DaysOfWeekCheckbox } from "../../components/forms/DaysOfWeekCheckbox";
import { VianikoEvent } from "../../types/events";
import { RecurringEvent } from "../../types/recurring_events";
import { Panel } from "../../components/Panel";
import {
  numberInputDefaultThemeProps,
  selectDefaultThemeProps,
} from "../../services/theme/overrides/input";

interface RecurringEventFormProps {
  onSubmit: (data: RecurringEvent) => void;
  values?: RecurringEvent;
}

export const RecurringEventForm: React.FC<RecurringEventFormProps> = ({
  onSubmit,
  values,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [frequencyInterval, setfrequencyInterval] = useState(1);
  const [frequencyType, setFrequencyType] = useState<number>(RRule.WEEKLY);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  useEffect(() => {
    if (values && !initialized) {
      const rrule = RRule.fromString(values.rrule);
      setfrequencyInterval(rrule.options.interval);
      setFrequencyType(rrule.options.freq);
      setSelectedDays(rrule.options.byweekday);
      setInitialized(true);
    }
  }, [initialized, values]);

  const handleChangeDays = (days: number[]) => {
    setSelectedDays(days);
  };

  const handleSubmit = async (data: VianikoEvent) => {
    const rrule = new RRule({
      freq: frequencyType,
      interval: frequencyInterval,
      byweekday: selectedDays,
      dtstart: new Date(data.start_at),
    });
    onSubmit({ ...data, rrule: rrule.toString() });
  };

  return (
    <>
      <EventForm
        onSubmit={handleSubmit}
        submitText={!!values ? "Save" : "Create recurring event"}
        defaultValues={values}
        timeOnly={true}
        formAddon={
          <Panel marginBottom={0}>
            <Text marginY={2}>Repeat every</Text>

            <HStack>
              <NumberInput
                value={frequencyInterval}
                onChange={(v) => setfrequencyInterval(parseInt(v))}
                defaultValue={1}
                min={1}
                width="100%"
              >
                <NumberInputField {...numberInputDefaultThemeProps} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select
                value={frequencyType}
                onChange={(e) => setFrequencyType(parseInt(e.target.value))}
                {...selectDefaultThemeProps}
              >
                <option value={RRule.DAILY}>
                  {frequencyInterval > 1 ? "days" : "day"}
                </option>
                <option value={RRule.WEEKLY}>
                  {frequencyInterval > 1 ? "weeks" : "week"}
                </option>
              </Select>
            </HStack>
            {frequencyType === RRule.WEEKLY && (
              <Box marginTop={4}>
                <Text marginY={2}>Repeat on</Text>
                <DaysOfWeekCheckbox
                  defaultValue={selectedDays}
                  onChange={handleChangeDays}
                />
              </Box>
            )}
          </Panel>
        }
      />
    </>
  );
};
