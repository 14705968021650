import { DeleteButtonConfirmation } from "./DeleteButtonConfirmation";
import * as api from "../services/api";

interface RemoveFriendButtonProps {
  userId: string;
  friendshipId: string;
  onRemove?: () => void;
}

export const RemoveFriendButton: React.FC<RemoveFriendButtonProps> = ({
  userId,
  friendshipId,
  onRemove,
}) => {
  const handleDelete = async () => {
    await api.friendships.removeFriendship(userId, friendshipId);
    onRemove?.();
  };

  return (
    <DeleteButtonConfirmation
      buttonProps={{ variant: "primary", margin: 0 }}
      onConfirm={handleDelete}
      actionText="Unfriend"
    />
  );
};
