import { Heading } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { VianikoEvent } from "../../types/events";
import * as api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { EventForm } from "./components/forms/EventForm";
import { useCurrentOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import { eventShowUrl } from "../../services/routes/urlBuilder";

export const NewEventPage: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const handleSubmit: SubmitHandler<VianikoEvent> = async (data) => {
    const event = await api.events.createEvent(data);
    navigate(eventShowUrl(event.id));
  };

  if (!!organizationId && !currentOrganizationUser?.is_owner) return null;

  return (
    <>
      <Heading as="h1" size="lg">
        Create Event
      </Heading>
      <EventForm onSubmit={handleSubmit} submitText="Create event" />
    </>
  );
};
